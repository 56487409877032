import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 40px 28px 30px;
  min-height: calc(100vh - 80px);
  @media (max-width: 768px) {
    padding: 20px 12px;
    min-height: unset;
  }
`;

export const FormLabel = styled.div`
  position: relative;
  display: none;
  margin-bottom: 20px;
  @media (max-width: 576px) {
    display: block;
  }
`;
export const Header = styled.div`
  display: flex;
  margin: auto;
  align-items: flex-start;
  justify-content: space-between;
  width: 90%;
  margin-bottom: 28px;
  @media (max-width: 576px) {
    width: 100%;
    align-items: center;
  }
`;
export const BackButton = styled.div`
  display: inline-block;
  position: absolute;
  width: 20px;
  height: auto;
  color: var(--dark-grey-1);
  @media (max-width: 576px) {
    & img {
      width: 100%;
      height: auto;
    }
  }
`;
export const Text = styled.div`
  display: block;
  text-align: center;
`;
export const Question = styled.div`
  font-weight: 700;
  font-size: 30px;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 20px;
  & span {
    color: var(--secondary-color);
  }
  @media (max-width: 576px) {
    font-size: 22px;
  }
`;
export const InputWrapper = styled.div`
  position: relative;
  display: block;
  margin: 0 auto;
  width: 60%;
  text-align: center;
  margin-bottom: 20px;
  height: 50px;
  & label {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  @media (max-width: 576px) {
    width: 100%;
  }
`;
export const TextInput = styled.input`
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  border: none;
  width: 100%;
  height: 50px;
  padding: 0 26px;
  font-size: 18px;
  &:focus {
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.2);
    outline: none;
  }
`;
