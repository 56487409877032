import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { StyledButton } from "../../../../components/Button/Button";
import Modal from "../../../../components/Modal/Modal";
import { setPADisclaimerAccepted } from "../../../../modules/quotes.slice";

const PADisclaimer: React.FC<{
  onBuyNow: () => void;
  open: boolean;
  onClose: () => void;
}> = ({ onBuyNow, ...props }) => {
  const dispatch = useDispatch();
  const [check1, setCheck1] = React.useState(false);
  const [check2, setCheck2] = React.useState(false);
  const onCheck1 = () => {
    setCheck1(!check1);
  };
  const onCheck2 = () => {
    setCheck2(!check2);
  };
  useEffect(() => {
    if (check1 || check2) {
      dispatch(setPADisclaimerAccepted(true));
    }
  }, [check1, check2]);
  return (
    <>
      <Modal
        title='Person Accident Cover is Mandatory as per IRDAI'
        open={props.open}
        onClose={props.onClose}
        maxWidth={"xs"}
      >
        <Stack p={2} spacing={1}>
          <Typography variant='body2'>You can only opt out if,</Typography>
          <FormControlLabel
            label='Owner already has PA cover of atleast 15 lacs'
            control={<Checkbox />}
            onChange={onCheck1}
            checked={check1}
          />
          <Typography variant='body2'> OR </Typography>
          <FormControlLabel
            label='The owner does not have driving license'
            control={<Checkbox />}
            onChange={onCheck2}
            checked={check2}
          />
          {check1 || check2 ? (
            <StyledButton variant='contained' onClick={onBuyNow}>
              Proceed
            </StyledButton>
          ) : (
            <></>
          )}
        </Stack>
      </Modal>
    </>
  );
};

export default PADisclaimer;
