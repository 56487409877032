import React from "react";
import { InputWrapper, Question, TextInput } from "../VehicleDetails.style";
import { ExpandableBox, VariantWrapper } from "./Forms.style";
import search from "./../../../../../assets/images/search.png";
import { useDispatch } from "react-redux";
import { setActiveVehicleDetails, setVariant } from "../../../../../modules";
import {
  useGetVariantQuery,
  VariantsInterface,
} from "../../../../../services/CarServices";
import useSearch from "../../../../../hooks/useSearch";
import MobileSectionLabel from "./MobileSectionLabel";
import { useMediaQuery } from "@mui/material";
const Variant = () => {
  const dispatch = useDispatch();
  const { variantsList, isVariantsLoading } = useGetVariantQuery();
  const { searchResults, searchText, onSearch } = useSearch<
    keyof VariantsInterface
  >("VARIANT_NAME", variantsList);
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <>
      {isMobile && (
        <MobileSectionLabel
          label='Select your Bike Variant'
          onClick={() => {}}
        />
      )}
      <Question>
        What is your bike's <span>Variant</span> ?
      </Question>
      <InputWrapper>
        <TextInput id='search' placeholder='Search your bike model here' />
        <label htmlFor='search'>
          <img src={search} alt='search'></img>
        </label>
      </InputWrapper>
      <ExpandableBox expand>
        {searchResults.map((variant) => (
          <VariantWrapper
            onClick={() => {
              dispatch(setActiveVehicleDetails(3));
              dispatch(
                setVariant({
                  id: variant.VERSION_ID,
                  title: variant.VARIANT_NAME,
                })
              );
            }}
          >
            {variant.VARIANT_NAME}
          </VariantWrapper>
        ))}
      </ExpandableBox>
    </>
  );
};

export default Variant;
