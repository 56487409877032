import { useLocation, useNavigate } from "react-router-dom";
interface SetUrlQueryOptions {
  replace: boolean;
}

/*  stages in landing page
    BRAND
    MODEL
    VARIANT
    YEAR
*/
// TODO type defination files of url params
const useUrlQuery = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const setUrlQuery = (
    key: string,
    value: string,
    options?: SetUrlQueryOptions
  ) => {
    searchParams.set(key, value);
    navigate(
      {
        search: searchParams.toString(),
      },
      { replace: options?.replace }
    );
  };

  const getUrlQuery = (key: string): string | null => {
    const value = searchParams.get(key);
    if (!value) return null;
    return value;
  };

  const removeUrlQuery = (key: string) => {
    searchParams.delete(key);
    navigate(
      {
        search: searchParams.toString(),
      },
      { replace: true }
    );
  };
  return { setUrlQuery, getUrlQuery, removeUrlQuery };
};

export default useUrlQuery;
