import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useGetEligibleProductsQuery } from "../../../../../services/CarServices";
import DetailsCard from "../DetailsCard";

const VehicleDetails = () => {
  const navigate = useNavigate();
  const { requestData } = useGetEligibleProductsQuery();
  if (!requestData) return <></>;
  return (
    <>
      <DetailsCard
        title={`${requestData.vehicle_make} ${requestData.vehicle_model} ${requestData.vehicle_variant}`}
        details={[
          "Private Bike",
          moment(requestData.registration_date, "YYYY-MM-DD").format("YYYY"),
          requestData.rto_number,
        ]}
        onEdit={() => {
          navigate({ pathname: "/", search: "?flow=old&stage=VEHICLE" });
        }}
        isVehicleDetails
      />
    </>
  );
};

export default VehicleDetails;
