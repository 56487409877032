import styled from "styled-components";
import React from "react";
interface ExpandableBoxProps {
  expand?: boolean;
}
interface BrandWrapperProps {
  active?: boolean;
}
export const ExpandableBox = styled.div<ExpandableBoxProps>`
  display: flex;
  width: 90%;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: auto;
  overflow-x: hidden;
  background-color: #fff;
  max-height: ${(props) => (props.expand ? "280px" : "240px")};
  padding: 20px 20px;
  overflow-y: ${(props) => (props.expand ? "auto" : "hidden")};
  margin-bottom: 20px;
  box-shadow: ${(props) => props.expand && "0px 4px 8px rgba(0, 0, 0, 0.08)"};
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(9, 114, 147, 0.2);
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 110, 144, 0.8);
  }
  @media (max-width: 768px) {
    padding: 20px 0;
    width: 100%;
  }
`;

export const BrandWrapper = styled.div<BrandWrapperProps>`
  width: calc(20% - 20px);
  margin: 0 10px 0;
  height: 100px;
  position: relative;
  margin-bottom: 20px;
  border: ${(props) =>
    props.active ? "0.3px solid #eaf1f6" : "0.5px solid #eaf1f6"};
  color: ${(props) =>
    props.active ? "var(--primary-color)" : "var(--dark-grey-1)"};

  background-color: #fff;
  box-shadow: ${(props) =>
    props.active
      ? "0px 6px 16px rgba(0, 110, 144, 0.12)"
      : "0px 4px 8px rgba(0, 110, 144, 0.02)"};
  cursor: pointer;
  & img,
  & div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  & img {
    width: auto;
    height: auto;
    max-width: 80%;
    max-height: 60%;
  }
  &:hover {
    border: 0.3px solid #eaf1f6;
    box-shadow: 0px 6px 16px rgba(0, 110, 144, 0.14);
  }
  @media (max-width: 991px) {
    width: calc(25% - 20px);
  }
  @media (max-width: 576px) {
    width: calc(33.3% - 20px);
  }
`;

export const ModelWrapper = styled(BrandWrapper)`
  height: unset;
  padding: 12px 0;
  width: calc(25% - 20px);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & div {
    position: relative;
    text-align: center;
    font-size: 14px;
    font-weight: ${(props) => (props.active ? "700" : "400")};
  }
  @media (max-width: 991px) {
    width: calc(33.3% - 20px);
    font-size: 14px;
  }
`;
export const VariantWrapper = styled(ModelWrapper)`
  width: calc(33% - 20px);
  @media (max-width: 991px) {
    text-align: center;
    width: calc(50% - 20px);
  }
`;
export const ExpandButtonWrapper = styled.div`
  width: 60%;
  margin: auto;
`;
