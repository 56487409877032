import styled from "styled-components";

export const EditWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.07);
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    box-shadow: none;
    margin-top: 0;
  }
`;
export const PolicyDetailsWrapper = styled.div`
  padding: 20px;
`;
export const InputWrapper = styled.div`
  margin-bottom: 20px;
`;
