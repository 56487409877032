import { styled, Tab } from "@mui/material";

const StyledTab = styled(Tab)({
  fontSize: "18px",
  fontWeight: 600,
  textTransform: "capitalize",
  height: "60px",
  border: "1px solid #E5E5E5;",
  color: "var(--dark-grey-1)",
  "&.Mui-selected": {
    color: "var(--primary-color)",
  },
});
export default StyledTab;
