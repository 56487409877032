import React, { useEffect, useState } from "react";
import { useQueries, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import api from "../app/api";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { setIDVValues } from "../modules/quotes.slice";
export interface ProductInterface {
  PRODUCTID: number;
  PRODUCT_NAME: string;
  PRODUCT_CODE: string;
  BUSINESS_TYPE: string;
  LOB: string;
  STATUS: string;
  OWNER_TYPE: string;
  POLICY_TYPE: string;
  IC: number;
  SELLER_TYPE: string;
  CREATED_DATE: string;
  UPDATED_DATE: string;
  IC_ID: number;
  INSURANCE_NAME: string;
  INSURANCE_SLUG: string;
  ADDRESS: string;
  CONTACT_DETAILS: string;
  CLAIM_FORM_LINK: string;
  BROCHURE_LINK: string;
  LOGO_PATH: string;
}
export interface PremiumInterface {
  MESSAGE: string;
  PREMIUM_DATA: {
    NET_PREMIUM: string;
    TOTAL_PREMIUM: string;
    BASIC_OD: string;
    BASIC_TP: string;
    NCB_DISCOUNT: string;
    CORRELATION_ID: any;
    IS_GARAGES_AVAILABLE?: "Y" | "N";
    ADDONS: {
      CAN_OPT: {
        NILL_DEPT: string;
        RSA: string;
        NCB_PROTECTION: string;
        tppd: string;
        liabilities: string;
        PA_COVER: string;
        unnamed_passenger_si: string;
        age_discount: string;
      };
    };
    IDV: string;
    IDV_MAX: string;
    IDV_MIN: string;
  };
}
interface QuoteInterface {
  status: 200 | 500;
  PRODUCT_DATA: ProductInterface;
  PREMIUM_DATA: PremiumInterface;
}
const useGetQuotes = () => {
  const { quote_end_points, idv_values } = useTypedSelector(
    (state) => state.quote
  );
  const { selected_plantype } = useTypedSelector((state) => state.quote);
  const [errors, setErrors] = useState<number[]>([]);
  const dispatch = useDispatch();
  const results = useQueries(
    quote_end_points.map((endPoint) => ({
      queryKey: endPoint,
      queryFn: () => api.get<QuoteInterface>(endPoint),
    }))
  );
  useEffect(() => {
    results.forEach((result) => {
      if (result.data?.data.status === 500) {
        if (!errors.includes(result.data.data.PRODUCT_DATA.PRODUCTID)) {
          setErrors([...errors, result.data.data.PRODUCT_DATA.PRODUCTID]);
        }
      }
      if (result.data?.data.PREMIUM_DATA.PREMIUM_DATA) {
        const { IDV_MAX, IDV_MIN } =
          result.data?.data.PREMIUM_DATA.PREMIUM_DATA;
        if (IDV_MAX && IDV_MIN) {
          dispatch(
            setIDVValues({
              IDV_MIN: Number(IDV_MIN),
              IDV_MAX: Number(IDV_MAX),
            })
          );
        }
      }
    });
  }, [results]);

  return {
    fetchedQuotes: results
      .filter(
        (item) =>
          item.data?.data?.status !== 500 && (item.isLoading || item.isSuccess)
      )
      .map((quote) => ({
        isQuoteLoading: quote.isLoading,
        premium_data: quote.data?.data.PREMIUM_DATA,
        product_data: quote.data?.data.PRODUCT_DATA,
      }))
      .sort((a, b) => {
        if (a.isQuoteLoading) return 1;
        else
          return (
            Number(a.premium_data?.PREMIUM_DATA.TOTAL_PREMIUM) -
            Number(b.premium_data?.PREMIUM_DATA.TOTAL_PREMIUM)
          );
      })
      .filter(
        (quote) =>
          quote.product_data?.POLICY_TYPE === selected_plantype ||
          selected_plantype === "ALL"
      ),
    failedQuotes: results
      .filter(
        (item) =>
          (item.isError || item.data?.data.status === 500) && !item.isLoading
      )
      .map((quote) => ({
        message: quote.data?.data.PREMIUM_DATA.MESSAGE,
        status: quote.data?.data.status,
        product_data: quote.data?.data.PRODUCT_DATA,
      })),
  };
};
interface GarageInterface {
  message: string;
  status: 200 | 500;
  response: {
    WorkshopName: string;
    Address: string;
    Phone: string;
  }[];
}
export const useGaragesQuery = ({
  pincode,
  company_slug,
}: {
  pincode: string;
  company_slug: string;
}) => {
  const { data, isLoading } = useQuery(
    ["garages", pincode],
    () =>
      api.post<GarageInterface>("/GetGaragesByPincode", {
        PINCODE: pincode,
        COMPANY_SLUG: company_slug,
      }),
    { enabled: !!(pincode && pincode.length === 6) }
  );

  return { garages: data?.data?.response || [], isLoading };
};

export { useGetQuotes };
