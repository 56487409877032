import React, { useState } from "react";
import { StyledButton } from "../../../../../components/Button/Button";
import { InputWrapper, Question, TextInput } from "../VehicleDetails.style";
import {
  ExpandableBox,
  ExpandButtonWrapper,
  ModelWrapper,
} from "./Forms.style";
import search from "./../../../../../assets/images/search.png";
import { useDispatch } from "react-redux";
import { setActiveVehicleDetails, setModel } from "../../../../../modules";
import {
  ModelsInterface,
  useGetModelQuery,
} from "../../../../../services/CarServices";
import useSearch from "../../../../../hooks/useSearch";
import MobileSectionLabel from "./MobileSectionLabel";
import { useMediaQuery } from "@mui/material";
const Model = () => {
  const [expand, setExpand] = useState(false);
  const { modelsList, isModelsLoading } = useGetModelQuery();
  const { searchResults, searchText, onSearch } = useSearch<
    keyof ModelsInterface
  >("MODEL_NAME", modelsList);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <>
      {isMobile && (
        <MobileSectionLabel label='Select your Bike Model' onClick={() => {}} />
      )}
      <Question>
        What is your bike's <span>Brand</span> ?
      </Question>
      <InputWrapper>
        <TextInput
          id='search'
          value={searchText}
          onChange={onSearch}
          placeholder='Search your bike model here'
        />
        <label htmlFor='search'>
          <img src={search} alt='search'></img>
        </label>
      </InputWrapper>
      <ExpandableBox expand={expand}>
        {searchResults.map((model) => (
          <ModelWrapper
            onClick={() => {
              dispatch(setActiveVehicleDetails(2));
              dispatch(
                setModel({ id: model.MODEL_ID, title: model.MODEL_NAME })
              );
            }}
          >
            {model.MODEL_NAME}
          </ModelWrapper>
        ))}
      </ExpandableBox>
      {!expand && (
        <ExpandButtonWrapper>
          <StyledButton
            variant='outlined'
            fullWidth
            onClick={() => {
              setExpand(true);
            }}
          >
            View all bikes
          </StyledButton>
        </ExpandButtonWrapper>
      )}
    </>
  );
};

export default Model;
