import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import FormCard from "../FormCard";
import StyledInput from "../../../../components/TextInput/TextInput";
import { StyledButton } from "../../../../components/Button/Button";
import { useDispatch } from "react-redux";
import { nextForm } from "../../../../modules/proposal.slice";
import {
  useGetProposalDetailsQuery,
  useUpdateProposalMutation,
  useVerifyPanCKYC,
} from "../../../../services/ProposalServices";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import {
  lowercase,
  onInputName,
  onInputPhone,
  uppercase,
} from "../../../../utils";
import { toast } from "react-toastify";
import useUrlQuery from "../../../../hooks/useUrlQuery";

interface OwnersData {
  owner_type: string;
  company_name?: string;
  first_name: string;
  last_name: string;
  pan_no: string;
  email: string;
  mobile_no: string;
}
const EditComp: React.FC = () => {
  const dispatch = useDispatch();
  const { asyncUpdateProposal } = useUpdateProposalMutation();
  const { proposalData } = useTypedSelector((state) => state.proposal);
  const { getUrlQuery } = useUrlQuery();
  const PROPOSAL_ID = getUrlQuery("proposal_id");
  const { verifyCKYC } = useVerifyPanCKYC();
  const { product_data } = useGetProposalDetailsQuery();
  const {
    handleChange,
    setValues,
    handleBlur,
    handleSubmit,
    values,
    touched,
    errors,
  } = useFormik<OwnersData>({
    enableReinitialize: true,
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      pan_no: "",
      mobile_no: "",
      owner_type: "IND",
    },
    onSubmit: async (values) => {
      dispatch(nextForm());
      await asyncUpdateProposal({
        ...proposalData,
        ...values,
      });
      if (
        product_data?.INSURANCE_SLUG === "hdfc_ergo" ||
        product_data?.INSURANCE_SLUG === "reliance"
      ) {
        const panStatus = await verifyCKYC({
          pan_no: values?.pan_no,
          PROPOSAL_ID,
        });
        if (panStatus?.data?.CKYC_LINK && panStatus?.data?.IS_CKYC === "N") {
          window.open(panStatus?.data?.CKYC_LINK, "_self");
        }
      }
    },
    validationSchema: yup.object().shape({
      owner_type: yup.string().required(),
      company_name: yup.string().when("owner_type", {
        is: "COMP",
        then: yup.string().required("Company Name is required"),
      }),
      first_name: yup.string().required("First name is required"),
      last_name: yup.string().required("Last name is required"),
      pan_no:
        product_data?.INSURANCE_SLUG === "hdfc_ergo"
          ? yup
              .string()
              .nullable()
              .required()
              .matches(
                /^[A-Z]{3}[P]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$|^$/,
                "Please enter a valid pan no."
              )
          : yup
              .string()
              .nullable()
              .matches(
                /^[A-Z]{3}[P]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$|^$/,
                "Please enter a valid pan no."
              ),
      email: yup
        .string()
        .required("Email is required")
        .email("Please enter a valid email"),
      mobile_no: yup
        .string()
        .required("Mobile number is required")
        .matches(/^[5-9]{1}[0-9]{9}$/, "Please enter a valid number"),
    }),
  });
  useEffect(() => {
    setValues({
      company_name: proposalData.company_name,
      owner_type: proposalData.owner_type || "IND",
      first_name: proposalData.first_name,
      last_name: proposalData.last_name,
      email: proposalData.email,
      pan_no: proposalData.pan_no,
      mobile_no: proposalData.mobile_no,
    });
  }, [proposalData]);

  useEffect(() => {
    if (values.owner_type === "COMP") {
      toast.info("For Corporate Policies Premium might subject to change");
    }
  }, [values.owner_type]);
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Stack
          sx={{
            mt: 2,
          }}
          spacing={2}
        >
          {" "}
          <Box>
            <Typography variant='subtitle1' component={"p"}>
              Bike registered in company name?
            </Typography>
            <RadioGroup
              name='owner_type'
              sx={{
                flexDirection: "row",
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.owner_type}
            >
              <FormControlLabel
                control={<Radio />}
                label='Yes'
                value={"COMP"}
              />
              <FormControlLabel control={<Radio />} label='No' value={"IND"} />
            </RadioGroup>
          </Box>
          {values.owner_type === "COMP" && (
            <StyledInput
              label={"Organisation Name, as in your RC copy"}
              size={"small"}
              onChange={(e) => {
                handleChange(e);
              }}
              name='company_name'
              onBlur={handleBlur}
              value={values.company_name}
              error={touched.company_name && Boolean(errors.company_name)}
              helperText={touched.company_name && errors.company_name}
            />
          )}
          <StyledInput
            label={
              values.owner_type === "COMP"
                ? "Contact Person's First Name"
                : "Owner's First Name"
            }
            size={"small"}
            onChange={(e) => {
              onInputName(e);
              handleChange(e);
            }}
            name='first_name'
            inputProps={{
              readOnly: proposalData?.ckyc,
            }}
            onBlur={handleBlur}
            value={values.first_name}
            error={touched.first_name && Boolean(errors.first_name)}
            helperText={touched.first_name && errors.first_name}
          />
          <StyledInput
            label={
              values.owner_type === "COMP"
                ? "Contact Person's Last Name"
                : "Owner's Last Name"
            }
            size={"small"}
            onChange={(e) => {
              onInputName(e);
              handleChange(e);
            }}
            inputProps={{
              readOnly: proposalData?.ckyc,
            }}
            name='last_name'
            value={values.last_name}
            onBlur={handleBlur}
            error={touched.last_name && Boolean(errors.last_name)}
            helperText={touched.last_name && errors.last_name}
          />
          <StyledInput
            label='Email Address'
            size={"small"}
            onChange={(e) => {
              lowercase(e);
              handleChange(e);
            }}
            name='email'
            value={values.email}
            onBlur={handleBlur}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
          />
          <StyledInput
            label='Mobile Number'
            size={"small"}
            onChange={(e) => {
              onInputPhone(e);
              handleChange(e);
            }}
            name='mobile_no'
            value={values.mobile_no}
            onBlur={handleBlur}
            inputProps={{
              maxLength: 10,
            }}
            error={touched.mobile_no && Boolean(errors.mobile_no)}
            helperText={touched.mobile_no && errors.mobile_no}
          />
          <StyledInput
            label='PAN Number'
            size={"small"}
            onChange={(e) => {
              uppercase(e);
              handleChange(e);
            }}
            name='pan_no'
            value={values.pan_no}
            onBlur={handleBlur}
            inputProps={{
              maxLength: 10,
              readOnly: proposalData?.ckyc,
            }}
            error={touched.pan_no && Boolean(errors.pan_no)}
            helperText={touched.pan_no && errors.pan_no}
          />
          <StyledButton type='submit' variant='contained' size='medium'>
            Proceed to Personal Details
          </StyledButton>
        </Stack>
      </form>
    </>
  );
};
const SummaryComp: React.FC = () => {
  return <></>;
};
//Mui Gender Radio component
const OwnerDetails = () => {
  return (
    <>
      <FormCard
        title='Owner Details'
        index={0}
        EditComp={EditComp}
        SummaryComp={SummaryComp}
      />
    </>
  );
};

export default OwnerDetails;
