import {
  IconButton,
  Typography,
  useMediaQuery,
  Stack,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StyledButton } from "../../../../components/Button/Button";
import Modal from "../../../../components/Modal/Modal";
import StyledRadioGroup from "../../../../components/RadioGroup/StyledRadioGroup";
import { SelectOptions } from "../../../../components/Select/Select";
import TextButton from "../../../../components/TextButton/TextButton";
import StyledInput from "../../../../components/TextInput/TextInput";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import useUrlQuery from "../../../../hooks/useUrlQuery";
import { closePopups } from "../../../../modules";
import {
  PreviousPolicyInterface,
  useGetEligibleProductsMutation,
} from "../../../../services/CarServices";
import { Question } from "../VehicleDetails/VehicleDetails.style";
import BackIcon from "@mui/icons-material/KeyboardBackspace";
import bike from "./../../../../assets/images/bike.svg";
import {
  BikeDetails,
  ExpiryDetails,
  Form,
  FormWrapper,
  Wrapper,
} from "./VehicleExpiry.style";
const policyStatusOptions: SelectOptions[] = [
  { OPTION_KEY: "Y", OPTION_VAL: "Expired" },
  { OPTION_KEY: "N", OPTION_VAL: "Not Expired" },
];
const yesNoOptions: SelectOptions[] = [
  { OPTION_KEY: "Y", OPTION_VAL: "Yes" },
  { OPTION_KEY: "N", OPTION_VAL: "No" },
];
const yesNoOtherOptions: SelectOptions[] = [
  { OPTION_KEY: "Y", OPTION_VAL: "Yes" },
  { OPTION_KEY: "N", OPTION_VAL: "No" },
  { OPTION_KEY: "O", OPTION_VAL: "I Don't Know" },
];
const VehicleExpiry = () => {
  const dispatch = useDispatch();
  const {
    showVehicleExpiryDetailsPopup,
    vehicleDetails: { brand, model, variant },
  } = useTypedSelector((state) => state.landing);
  const { getEligibleProducts } = useGetEligibleProductsMutation();
  const navigate = useNavigate();
  const { removeUrlQuery, setUrlQuery } = useUrlQuery();

  const { handleSubmit, handleChange, values } =
    useFormik<PreviousPolicyInterface>({
      initialValues: {
        policy_status: "N",
        claim_status: "N",
        mobile_number: "",
        policy_expired_before_90_days: "N",
      },
      onSubmit: (values) => {
        getEligibleProducts({
          is_claim_made_last_year: values.claim_status,
          is_policy_expired: values.policy_status,
          mobile_no: values.mobile_number,
          policy_expired_before_90_days: values.policy_expired_before_90_days,
        });
        // navigate({ pathname: "/quote" });
      },
    });
  const onClose = () => {
    dispatch(closePopups());
    removeUrlQuery("flow");
    removeUrlQuery("stage");
  };
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <>
      <Modal
        open={showVehicleExpiryDetailsPopup}
        onClose={onClose}
        maxWidth="md"
        fullScreen={isMobile}
      >
        <Wrapper>
          <BikeDetails>
            <Stack
              sx={{
                py: "100px",
                px: 3,
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Typography variant="h6" fontWeight={"600"} color="#111">
                <IconButton
                  color="inherit"
                  sx={{
                    mt: "-4px",
                  }}
                  onClick={() => {
                    navigate({
                      search: "?stage=VEHICLE&flow=all",
                    });
                  }}
                >
                  <BackIcon />
                </IconButton>
                Your Bike
              </Typography>
              <img
                src={bike}
                alt="bike"
                style={{
                  marginLeft: "-60px",
                  marginTop: "60px",
                  height: "200px",
                }}
              />
              <Box
                sx={{
                  pl: 2,
                }}
              >
                <Typography
                  variant="h6"
                  fontSize="20px"
                  fontWeight={"600"}
                  color="#111"
                >
                  {brand.title} {model.title}
                </Typography>
                <Typography
                  variant="h6"
                  fontSize="18px"
                  fontWeight={"600"}
                  color="#747989"
                >
                  {variant.title}
                </Typography>
                <Button
                  variant="text"
                  sx={{
                    textTransform: "none",
                    p: 0,
                  }}
                  onClick={() => {
                    navigate({
                      search: "?stage=VEHICLE&flow=all",
                    });
                  }}
                >
                  Not Your Bike ?
                </Button>
              </Box>
            </Stack>
          </BikeDetails>
          <ExpiryDetails>
            {isMobile && (
              <>
                <Box display={"flex"} justifyContent="space-between" px={2}>
                  <Typography
                    width={"80%"}
                    sx={{
                      color: "var(--tertiary-color)",
                      fontWeight: "600",
                      "& span": {
                        color: "var(--dark-grey-1)",
                      },
                    }}
                  >
                    {brand.title}, {model.title} <span>{variant.title}</span>
                  </Typography>
                  <TextButton
                    color="secondary"
                    style={{
                      fontSize: "16px",
                    }}
                    onClick={() => {
                      setUrlQuery("stage", "VEHICLE");
                    }}
                  >
                    Edit
                  </TextButton>
                </Box>
              </>
            )}
            <FormWrapper>
              <Question>
                You Are Just A <span>Step</span> Away !
              </Question>
              <Form onSubmit={handleSubmit}>
                <StyledRadioGroup
                  label="Tell us if your policy has expired :"
                  defaultValue={values.policy_status}
                  items={policyStatusOptions}
                  name="policy_status"
                  handleChange={handleChange}
                />
                {values.policy_status === "Y" && (
                  <StyledRadioGroup
                    label={`Did your policy expire before ${moment()
                      .subtract(90, "days")
                      .format("DD MMMM YYYY")} ?`}
                    items={yesNoOtherOptions}
                    defaultValue={values.policy_expired_before_90_days}
                    name="policy_expired_before_90_days"
                    handleChange={handleChange}
                  />
                )}
                {(values.policy_status === "N" ||
                  (values.policy_expired_before_90_days === "N" &&
                    values.policy_status === "Y")) && (
                  <StyledRadioGroup
                    label="Have you made a claim in the previous year?"
                    items={yesNoOptions}
                    defaultValue={values.claim_status}
                    name="claim_status"
                    handleChange={handleChange}
                  />
                )}
                <StyledInput
                  label="Mobile Number"
                  name="mobile_number"
                  value={values.mobile_number}
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 10,
                  }}
                />
                <div style={{ width: "100%" }}>
                  <StyledButton
                    type="submit"
                    variant="contained"
                    enlarged
                    fullWidth
                  >
                    View Quotes
                  </StyledButton>
                  <p className="my-1">
                    By clicking I agree to all{" "}
                    <a href="https://www.vkover.com/terms-and-conditions/">
                      T&C
                    </a>
                  </p>
                </div>
              </Form>
            </FormWrapper>
          </ExpiryDetails>
        </Wrapper>
      </Modal>
    </>
  );
};

export default VehicleExpiry;
