import React from "react";
import { Container } from "react-bootstrap";
import Bike from "../../components/Bike/Bike";
import EditDetails from "./components/EditDetails/EditDetails";
import Filters from "./components/Filters/Filters";
import NoQuotes from "./components/NoQuotes/NoQuotes";
import Quotes from "./components/Quotes/Quotes";

const QuotesPage = () => {
  return (
    <>
      <Container>
        <EditDetails />
        <Filters />
        <Quotes />
        <NoQuotes />
      </Container>
    </>
  );
};

export default QuotesPage;
