import { createSlice } from "@reduxjs/toolkit";

const proposalSlice = createSlice({
  name: "proposal",
  initialState: {
    activeForm: 0,
    proposalData: {} as any,
  },
  reducers: {
    nextForm: (state) => {
      state.activeForm = state.activeForm + 1;
    },
    prevForm: (state) => {
      state.activeForm = state.activeForm - 1;
    },
    setActiveForm: (state, action) => {
      state.activeForm = action.payload;
    },
    setProposalData: (state, action) => {
      state.proposalData = {
        ...state.proposalData,
        ...action.payload,
      };
    },
  },
});

export const { nextForm, prevForm, setActiveForm, setProposalData } =
  proposalSlice.actions;

export default proposalSlice.reducer;
