import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#006F8F",
    },
    secondary: {
      main: "#F25A23",
    },
  },
  typography: {
    fontFamily: "Nunito,sans-serif",
    fontSize: 16,
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        fullWidth: true,
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
    },
  },
});
export type Theme = typeof theme;
