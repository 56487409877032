import { Divider, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { StyledButton } from "../../../../components/Button/Button";
import SectionDetail from "../../../../components/SectionDetail/SectionDetail";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import useUrlQuery from "../../../../hooks/useUrlQuery";
import { editExistingPolicyDetails } from "../../../../modules/quotes.slice";
import { useInitiateProposalMutation } from "../../../../services/ProposalServices";
import { commaSeparatedPrice } from "../../../../utils";
import PADisclaimer from "../PADisclaimer/PADisclaimer";

const PlanSummary = () => {
  const {
    see_details: { quoteData },
    isPADisclaimerAccepted,
  } = useTypedSelector((state) => state.quote);
  const { getUrlQuery } = useUrlQuery();
  const stage = getUrlQuery("stage");
  const dispatch = useDispatch();
  const { premium_data, product_data, selected_addons } = quoteData;
  const additionalPremium = selected_addons.reduce(
    (acc, curr) => acc + Number(curr.premium),
    0
  );

  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  const isPAOpted = selected_addons.some((item) => item.slug === "PA_COVER");
  const isOD = product_data?.POLICY_TYPE === "OD";
  const { initiateProposal } = useInitiateProposalMutation();
  const onBuyNow = () => {
    if (stage !== "PF") {
      dispatch(editExistingPolicyDetails());
      return;
    }
    if (!(isPAOpted || isOD || isPADisclaimerAccepted)) {
      setOpen(true);
      return;
    }
    if (product_data && premium_data)
      initiateProposal({
        PRODUCT_ID: product_data.PRODUCTID,
        COMPANY_SLUG: product_data.INSURANCE_SLUG,
        TOTAL_PREMIUM: (
          +premium_data?.PREMIUM_DATA.TOTAL_PREMIUM + +additionalPremium
        ).toString(),
        GST:
          +premium_data?.PREMIUM_DATA.TOTAL_PREMIUM -
          +premium_data?.PREMIUM_DATA.NET_PREMIUM,
        IDV: premium_data.PREMIUM_DATA.IDV,
        BASIC_OD: premium_data.PREMIUM_DATA.BASIC_OD,
        BASIC_TP: premium_data.PREMIUM_DATA.BASIC_TP,
        NCB_DISCOUNT: premium_data.PREMIUM_DATA.NCB_DISCOUNT,
        ADDONS: selected_addons,
        CORRELATION_ID: premium_data?.PREMIUM_DATA?.CORRELATION_ID,
      });
  };
  return (
    <>
      <Stack
        sx={{
          p: "24px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.02)",
          borderRadius: "3px",
          width: "100%",
        }}
        spacing={1}
      >
        <img
          src={quoteData.product_data.LOGO_PATH}
          alt={quoteData.product_data.INSURANCE_NAME}
          style={{
            width: "140px",
            height: "auto",
          }}
        />
        <Typography
          variant="h4"
          sx={{
            color: "var(--tertiary-color)",
            fontWeight: 700,
            fontSize: "18px",
          }}
        >
          {quoteData.product_data.INSURANCE_NAME}
        </Typography>
        <Divider />
        <SectionDetail
          title="Cover Value"
          value={commaSeparatedPrice(quoteData.premium_data.PREMIUM_DATA.IDV)}
        />
        <StyledButton isBuyNow variant="outlined" onClick={onBuyNow}>
          <>
            <span className="btn-span">Buy Now</span>
            {commaSeparatedPrice(
              quoteData.premium_data?.PREMIUM_DATA.TOTAL_PREMIUM,
              additionalPremium
            )}
          </>
        </StyledButton>
      </Stack>
      <PADisclaimer onBuyNow={onBuyNow} open={open} onClose={onClose} />
    </>
  );
};

export default PlanSummary;
