import { useEffect } from "react";
import { Container } from "react-bootstrap";
import Bike from "../../components/Bike/Bike";
import useUrlQuery from "../../hooks/useUrlQuery";
import bike from "./../../assets/images/bike.svg";
import LandingForm from "./components/LandingForm/LandingForm";
import VehicleDetails from "./components/VehicleDetails/VehicleDetails";
import VehicleExpiry from "./components/VehicleExpiry/VehicleExpiry";
import {
  FormWrapper,
  Heading,
  HeadingWrapper,
  MainWrapper,
} from "./Landing.style";

const Landing = () => {
  const { setUrlQuery } = useUrlQuery();
  useEffect(() => {
    setUrlQuery("stage", "VEHICLE");
    setUrlQuery("flow", "all");
  }, []);
  return (
    <>
      <Container>
        <MainWrapper>
          <HeadingWrapper>
            <Heading> Insurance Made Easy</Heading>
            <Heading secondary>Yes It Is !!!</Heading>
          </HeadingWrapper>
          <FormWrapper>
            <LandingForm />
          </FormWrapper>
        </MainWrapper>
        <VehicleDetails />
        <VehicleExpiry />
      </Container>
    </>
  );
};

export default Landing;
