import { useQuery } from "react-query";
import api from "../app/api";
import useUrlQuery from "../hooks/useUrlQuery";

export const useStatusQuery = () => {
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const { data, isLoading, isSuccess } = useQuery(
    ["status", proposal_id],
    async () =>
      await api.post("/GetPolicyDetails", { PROPOSAL_ID: proposal_id })
  );

  return { status: data?.data?.POLICY_DATA[0], isLoading, isSuccess };
};
export const useGetDocument = () => {
  const { isSuccess, status } = useStatusQuery();
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const { data, isLoading, refetch } = useQuery(
    ["document"],
    () => api.post("/BikeGenerateDocument", { PROPOSAL_ID: proposal_id }),
    {
      enabled: isSuccess && !status.POLICY_DOC,
    }
  );
  return { data: data?.data?.response?.POLICY_DOC, isLoading, refetch };
};
