import styled from "styled-components";
import background from "../../assets/images/background.svg";
// Contains heading and landing page form.
export const MainWrapper = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
  background-image: url(${background});
  background-size: cover;
  background-position: -20px -70px;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const HeadingWrapper = styled.div`
  position: absolute;
  top: 20%;
  left: 50px;
  @media (max-width: 768px) {
    position: unset;
    top: unset;
    left: unset;
  }
`;

export const FormWrapper = styled.div`
  position: absolute;
  top: calc(20% - 20px);
  right: 0;
  @media (max-width: 768px) {
    position: unset;
    top: unset;
    left: unset;
  }
`;

interface HeadingProps {
  secondary?: boolean;
}
export const Heading = styled.div<HeadingProps>`
  font-size: 40px;
  font-weight: 700;
  color: var(--tertiary-color);
  color: ${(props) => props.secondary && "var(--secondary-color)"};
  @media (max-width: 768px) {
    font-size: 28px;
    text-align: center;
  }
`;
