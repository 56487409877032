import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landing from "./pages/Landing/Landing";
import Header from "./components/Header/Header";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./styles/theme";
import "./App.scss";
import QuotesPage from "./pages/QuotesPage/QuotesPage";
import ProposalPage from "./pages/Proposal/ProposalPage";
import ProposalSummary from "./pages/ProposalSummary/ProposalSummary";
import ThankYou from "./pages/ThankYou/ThankYou";
import { ReactQueryDevtools } from "react-query/devtools";
import ShareButton from "./components/ShareButton/ShareButton";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Footer from "./components/Footer/Footer";
import LeadCapture from "./pages/LeadCapture/LeadCapture";
function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
      },
    },
  });
  return (
    <>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <Router>
              <Header />
              <Routes>
                <Route path='/' element={<Landing />} />
                <Route path='/quotes' element={<QuotesPage />} />
                <Route path='/proposal' element={<ProposalPage />} />
                <Route path='/summary' element={<ProposalSummary />} />
                <Route path='/thankyou' element={<ThankYou />} />
                <Route path='/qr/:id' element={<LeadCapture />} />
              </Routes>
              <Footer />
              <ShareButton />
            </Router>
            <ReactQueryDevtools initialIsOpen={false} />
            <ToastContainer
              position='bottom-right'
              autoClose={3000}
              limit={3}
              rtl={false}
              pauseOnHover
              theme='colored'
            />
          </ThemeProvider>
        </QueryClientProvider>
      </Provider>
    </>
  );
}

export default App;
