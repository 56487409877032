import styled from "styled-components";

export const BikeDetails = styled.div`
  background: rgb(247, 247, 249);
  height: 100%;
  width: 33.3%;
  @media (max-width: 768px) {
    display: none;
  }
`;
export const ExpiryDetails = styled.div`
  width: 66.6%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    justify-content: space-around;
  }
`;
export const Wrapper = styled.div`
  height: calc(100vh - 80px);
  display: flex;
`;
export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;
export const Form = styled.form`
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const InputWrapper = styled.div``;
