import styled from "@emotion/styled";
import { Button, ButtonProps } from "@mui/material";
interface StyledButtonProps extends ButtonProps {
  variant: "outlined" | "contained" | "text";
  enlarged?: boolean;
  isBuyNow?: false | undefined;
}
interface BuyButtonProps extends ButtonProps {
  variant: "outlined";
  enlarged?: boolean;
  isBuyNow: true;
}

export const StyledButton = styled(Button)<StyledButtonProps | BuyButtonProps>(
  ({ variant, enlarged, isBuyNow }) => ({
    ...(variant === "outlined" && {
      "&,&:hover": {
        backgroundColor: "#fff",
        border: "2px solid var(--primary-color)",
      },
    }),
    ...(variant === "outlined" &&
      isBuyNow && {
        "&": {
          backgroundColor: "#fff",
          border: "2px solid var(--secondary-color)",
          color: "var(--secondary-color)",
          fontSize: "20px",
        },
        "&:hover": {
          backgroundColor: "var(--secondary-color)",
          color: "#fff",
          border: "2px solid var(--secondary-color)",
          "&>.btn-span": {
            color: "#fff",
          },
        },
        "&>.btn-span": {
          color: "var(--tertiary-color)",
          fontWeight: 400,
          marginRight: "16px",
          fontSize: "14px",
        },
      }),
    ...(variant === "contained" && {
      "&,&:hover": {
        backgroundColor: "var(--primary-color)",
      },
      border: "1px solid var(--primary-color)",
    }),
    ...(enlarged && {
      padding: "12px 16px",
    }),
    minWidth: "120px",
    borderRadius: "1px",
    fontWeight: 700,
    outline: "none",
    textTransform: "capitalize",
  })
);
