import { Box, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Modal from "../../../../../components/Modal/Modal";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import useUrlQuery from "../../../../../hooks/useUrlQuery";
import { setSelectedPlanType } from "../../../../../modules/quotes.slice";
import { useGetEligibleProductsQuery } from "../../../../../services/CarServices";
// this will open when url has filter param with value plantype
const PlanTypeModal = () => {
  const { getUrlQuery, removeUrlQuery } = useUrlQuery();
  const isOpen = getUrlQuery("filter") === "planType";
  const { requestData, isEligibleProductsLoading } =
    useGetEligibleProductsQuery();
  const dispatch = useDispatch();
  const { selected_plantype } = useTypedSelector((state) => state.quote);
  const planTypes = requestData?.policy_type;

  if (isEligibleProductsLoading) return null;

  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => {
          removeUrlQuery("filter");
        }}
        maxWidth={"xs"}
        title='Plan Type'
      >
        <Stack divider={<Divider />}>
          {planTypes && planTypes?.length > 1 && (
            <>
              <Box
                sx={{
                  p: 2,
                }}
                onClick={() => {
                  dispatch(setSelectedPlanType("ALL"));
                  removeUrlQuery("filter");
                }}
              >
                <Typography variant='h6' component='h6'>
                  All
                </Typography>
              </Box>
            </>
          )}
          {planTypes?.map((planType) => (
            <>
              <Box
                sx={{
                  p: 2,
                  cursor: "pointer",
                }}
                onClick={() => {
                  dispatch(setSelectedPlanType(planType.code));
                  removeUrlQuery("filter");
                }}
              >
                <Typography
                  variant='h6'
                  component='h6'
                  fontSize={"22px"}
                  fontWeight='bold'
                  sx={{
                    color:
                      selected_plantype === planType.code
                        ? "var(--secondary-color)"
                        : "",
                  }}
                >
                  {planType.display_name}
                </Typography>
                <Typography variant='body2' component='p' fontSize={"12px"}>
                  {planType.description}
                </Typography>
              </Box>
            </>
          ))}
        </Stack>
      </Modal>
    </>
  );
};

export default PlanTypeModal;
