import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../app/api";
import { SelectOptions } from "../components/Select/Select";
import { useTypedSelector } from "../hooks/useTypedSelector";
import useUrlQuery from "../hooks/useUrlQuery";
import { setProposalData } from "../modules/proposal.slice";
import { BikeResponse } from "./CarServices";
export interface addonsInterface {
  name: string;
  slug: string;
  premium: string | undefined;
}
interface InitiateBikeProposalRequest {
  PRODUCT_ID: number;
  BASIC_OD: string;
  BASIC_TP: string;
  NCB_DISCOUNT: string;
  GST: number;
  COMPANY_SLUG: string;
  TOTAL_PREMIUM: string;
  IDV: string;
  ADDONS: addonsInterface[];
  CORRELATION_ID: any;
}
export const useInitiateProposalMutation = () => {
  const { getUrlQuery } = useUrlQuery();
  const stage = getUrlQuery("stage");
  const quote_id = getUrlQuery("quote_id");
  const navigate = useNavigate();
  const { isLoading, mutate } = useMutation(
    (req: InitiateBikeProposalRequest) => {
      return api.post("/InitiateBikeProposal", {
        ...req,
        QUOTE_ID: quote_id,
      });
    },

    {
      onSuccess: ({ data }) => {
        const proposal_id = data.response.PROPOSAL_ID;
        navigate(`/proposal?proposal_id=${proposal_id}`);
      },
    }
  );

  return { initiateProposal: mutate, isSubmitting: isLoading };
};
interface PincodeRequest {
  pincode: string;
}
interface PincodeResponse extends BikeResponse {
  response: {
    city: SelectOptions[];
    state: [SelectOptions];
  };
}
interface ProposalDetailsResponse {
  data: {
    PROPOSAL: {
      _id: string;
      PRODUCT_ID: number;
      COMPANY_SLUG: string;
      TOTAL_PREMIUM: string;
      IDV: string;
      ADDONS: addonsInterface[];
      QUOTE_ID: string;
      FORMDATA?: any;
      BASIC_OD: string;
      BASIC_TP: string;
      NCB_DISCOUNT: string;
      GST: number;
    };
    QUOTE_REQUEST: {
      _id: string;
      vehicle_make: string;
      vehicle_model: string;
      vehicle_variant: string;
      vehicle_version_id: string;
      fuel_type: string;
      rto_number: string;
      lob: string;
      is_claim_made_last_year: string;
      is_policy_expired: string;
      mobile_no: string;
      existing_exp_date: string;
      registration_date: string;
      manf_month: string;
      prev_ncb: number;
      IDV?: string;
    };
    product_data: {
      PRODUCTID: number;
      PRODUCT_NAME: string;
      PRODUCT_CODE: string;
      BUSINESS_TYPE: string;
      LOB: string;
      STATUS: string;
      OWNER_TYPE: string;
      POLICY_TYPE: string;
      IC: number;
      SELLER_TYPE: string;
      IC_ID: number;
      INSURANCE_NAME: string;
      INSURANCE_SLUG: string;
      ADDRESS: string;
      CONTACT_DETAILS: string;
      CLAIM_FORM_LINK: string;
      BROCHURE_LINK: string;
      LOGO_PATH: string;
    };
  };
}
interface MasterResponse extends BikeResponse {
  response: SelectOptions[];
}
export const useGetOccupationQuery = () => {
  const { proposal_data } = useGetProposalDetailsQuery();
  const { isLoading, data } = useQuery(
    ["occupation", proposal_data?.COMPANY_SLUG],
    () => {
      return api.post<MasterResponse>("/GetOccupationMaster", {
        COMPANY_SLUG: proposal_data?.COMPANY_SLUG,
        LOB: "BIKE",
      });
    }
  );
  return {
    isOccupationLoading: isLoading,
    occupation_list: data?.data.response,
  };
};
export const useGetPreviousInsurerQuery = () => {
  const { proposal_data } = useGetProposalDetailsQuery();
  const { isLoading, data } = useQuery(
    ["prev_insurer", proposal_data?.COMPANY_SLUG],
    () => {
      return api.post<MasterResponse>("/GetPreviousInsurerMaster", {
        COMPANY_SLUG: proposal_data?.COMPANY_SLUG,
        LOB: "BIKE",
      });
    }
  );
  return {
    isInsurerLoading: isLoading,
    insurer_list: data?.data.response,
  };
};
export const useGetRelationMasterQuery = () => {
  const { proposal_data } = useGetProposalDetailsQuery();
  const { isLoading, data } = useQuery(
    ["relation", proposal_data?.COMPANY_SLUG],
    () => {
      return api.post<MasterResponse>("/GetRelationMaster", {
        LOB: "car",
        COMPANY_SLUG: proposal_data?.COMPANY_SLUG,
      });
    }
  );

  return {
    relations: data?.data?.response,
    isRelationsLoading: isLoading,
  };
};
export const useGetFinancierQuery = () => {
  const { proposal_data } = useGetProposalDetailsQuery();
  const { isLoading, data } = useQuery(
    ["financier", proposal_data?.COMPANY_SLUG],
    () => {
      return api.post<MasterResponse>("/GetFinancierMaster", {
        COMPANY_SLUG: proposal_data?.COMPANY_SLUG,
        LOB: "BIKE",
      });
    }
  );
  return {
    isFinancierLoading: isLoading,
    financier_list: data?.data.response,
  };
};
export const useGetProposalDetailsQuery = () => {
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const dispatch = useDispatch();
  const { isLoading, data } = useQuery(
    ["proposal_details", proposal_id],
    () => {
      return api.post<ProposalDetailsResponse>("/GetBikeProposalDetails", {
        PROPOSAL_ID: proposal_id,
      });
    },
    {
      onSuccess: (data) => {
        dispatch(setProposalData(data.data.data.PROPOSAL.FORMDATA));
      },
      enabled: !!proposal_id,
    }
  );

  return {
    proposal_data: data?.data?.data?.PROPOSAL,
    quote_data: data?.data?.data?.QUOTE_REQUEST,
    product_data: data?.data?.data?.product_data,
    isProposalLoading: isLoading,
  };
};
export const useUpdateProposalMutation: any = (options: any) => {
  const isFormData: boolean = options?.isFormData;
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const dispatch = useDispatch();
  const { activeForm } = useTypedSelector((state) => state.proposal);
  const { isLoading, mutate, isSuccess, mutateAsync } = useMutation(
    (req: any) => {
      const formData = new FormData();
      const newData = { PROPOSAL_ID: proposal_id, ...req };
      Object.keys(newData).forEach((item) => {
        formData.append(item, newData[item]);
      });
      dispatch(setProposalData(req));
      return api.post(
        "/UpdateBikeRawProposal",
        isFormData
          ? formData
          : {
              FORMDATA: req,
              PROPOSAL_ID: proposal_id,
            },
        {
          headers: {
            "Content-Type": isFormData
              ? "multipart/form-data"
              : "application/json",
          },
        }
      );
    },
    {
      onSuccess: () => {
        toast.success("Data Saved Successfully");
      },
    }
  );
  return {
    updateProposal: mutate,
    isSubmitting: isLoading,
    isSubmitted: isSuccess,
    asyncUpdateProposal: mutateAsync,
  };
};
export const useGetPincodeMutation = () => {
  const { proposal_data } = useGetProposalDetailsQuery();
  const { isLoading, data, isError, mutate, isSuccess } = useMutation(
    ({ pincode }: PincodeRequest) => {
      return api.post<PincodeResponse>("/GetPincodeMaster", {
        pincode,
        company_alias: proposal_data?.COMPANY_SLUG,
      });
    }
  );
  return {
    isCityStateLoading: isLoading,
    getCityState: mutate,
    cityOptions: data?.data?.response?.city,
    stateOptions: data?.data?.response?.state,
    isError,
    isCityStateFetched: isSuccess,
  };
};
export const serialize = function (obj: any) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};
export const useProposalFinalSubmitMutation = () => {
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { isLoading, mutate, data } = useMutation(
    () => {
      return api.post("/BikeProposalFinalSubmit", {
        PROPOSAL_ID: proposal_id,
      });
    },
    {
      onSuccess: (data) => {
        if (data.data.status === 500) {
          toast.error(data.data.message);
        }
        if (data.data.status === 200) {
          queryClient.invalidateQueries("proposal_details");

          navigate({
            pathname: "/summary",
            search: `?proposal_id=${proposal_id}`,
          });
        }
      },
    }
  );
  return {
    isSubmitting: isLoading,
    submitProposal: mutate,
    data: data?.data,
  };
};

export const usePaymentQuery = () => {
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const navigate = useNavigate();
  const { isLoading, data } = useQuery(
    ["payment", proposal_id],
    () => {
      return api.post("/GetBikePaymentDetails", {
        PROPOSAL_ID: proposal_id,
      });
    },
    {
      onSuccess: (data) => {
        if (data.data.status === 500 && !data.data.resubmit) {
          navigate({
            pathname: "/proposal",
            search: `?proposal_id=${proposal_id}`,
          });
          toast.error(data.data.message);
        }
      },
    }
  );
  return { paymentData: data?.data, isPaymentLoading: isLoading };
};
export const useUpdateOrderMutation = () => {
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");

  const { isLoading, mutate, isSuccess } = useMutation(() =>
    api.post("/UpdateOrderStage", {
      PROPOSAL_ID: proposal_id,
      ORDER_STAGE: "PG REDIRECTED",
    })
  );
  return { updateOrder: mutate, isLoading, isSuccess };
};
export const useVerifyPanCKYC = () => {
  const { product_data } = useGetProposalDetailsQuery();
  const queryClient = useQueryClient();
  const { data, mutate, mutateAsync, isLoading, reset } = useMutation(
    (data: any) => {
      return api.post(
        product_data?.INSURANCE_SLUG === "reliance"
          ? "/GetRelianceCKYCDetails"
          : "/GetHdfcCKYCDetails",
        { ...data, LOB: "BIKE" }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["proposal_details"]);
      },
    }
  );

  return {
    verifyCKYC: mutateAsync,
    isVerifyCKYC: isLoading,
    resetVerify: reset,
  };
};
