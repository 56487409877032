import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import FormCard from "../FormCard";
import StyledInput from "../../../../components/TextInput/TextInput";
import { StyledButton } from "../../../../components/Button/Button";
import { useDispatch } from "react-redux";
import { nextForm, setActiveForm } from "../../../../modules/proposal.slice";
import {
  useGetFinancierQuery,
  useGetPreviousInsurerQuery,
  useGetProposalDetailsQuery,
  useProposalFinalSubmitMutation,
  useUpdateProposalMutation,
} from "../../../../services/ProposalServices";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import Select from "../../../../components/Select/Select";
import moment from "moment";
import DatePicker from "../../../../components/DatePicker/DatePicker";
import {
  allowRegistrationNumber,
  checkAlphanumeric,
  noSpecialExceptHyphen,
} from "../../../../utils";
import Bike from "../../../../components/Bike/Bike";

interface VehicleData {
  registration_number?: string;
  engine_number?: string;
  chassis_number?: string;
  prev_insurer_val?: string;
  prev_insurer_name?: string;
  is_financed?: string;
  financier_name?: string;
  financier_val?: string;
  prev_policy_no?: string;
  tp_prev_insurer_val: string;
  tp_prev_insurer_name: string;
  tp_prev_policy_no: string;
  tp_existing_exp_date: string;
}
const EditComp: React.FC = () => {
  const dispatch = useDispatch();
  const { product_data, quote_data } = useGetProposalDetailsQuery();
  const {
    updateProposal,
    isSubmitted,
    isSubmitting: isProposalSaving,
  } = useUpdateProposalMutation();
  const { submitProposal, isSubmitting } = useProposalFinalSubmitMutation();
  const { proposalData } = useTypedSelector((state) => state.proposal);
  const { insurer_list } = useGetPreviousInsurerQuery();
  const { financier_list } = useGetFinancierQuery();
  useEffect(() => {
    if (
      isSubmitted &&
      !isProposalSaving &&
      product_data?.INSURANCE_SLUG !== "godigit" &&
      product_data?.INSURANCE_SLUG !== "icici_lombard_general"
    ) {
      submitProposal();
    } else if (
      isSubmitted &&
      !isProposalSaving &&
      (product_data?.INSURANCE_SLUG === "godigit" || product_data?.INSURANCE_SLUG === "icici_lombard_general")
    ) {
      dispatch(setActiveForm(4));
    }
  }, [isSubmitted, isProposalSaving, product_data]);
  const isOD = product_data?.POLICY_TYPE === "OD";
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    values,
    touched,
    errors,
    setValues,
  } = useFormik<VehicleData>({
    enableReinitialize: true,
    initialValues: {
      registration_number: "",
      engine_number: "",
      chassis_number: "",
      prev_insurer_val: "",
      prev_insurer_name: "",
      prev_policy_no: "",
      tp_prev_insurer_val: "",
      tp_prev_insurer_name: "",
      tp_prev_policy_no: "",
      tp_existing_exp_date: moment(quote_data?.registration_date, "YYYY-MM-DD")
        .add(5, "years")
        .subtract(1, "days")
        .format("YYYY-MM-DD"),
      financier_name: "",
      financier_val: "",
      is_financed: "N",
    },
    onSubmit: (values) => {
      updateProposal({
        ...proposalData,
        ...values,
        financier_name: financier_list?.find(
          (financier) => financier.OPTION_KEY === values.financier_val
        )?.OPTION_VAL,
        prev_insurer_name: insurer_list?.find(
          (insurer) => insurer.OPTION_KEY === values.prev_insurer_val
        )?.OPTION_VAL,
        tp_prev_insurer_name: insurer_list?.find(
          (insurer) => insurer.OPTION_KEY === values.tp_prev_insurer_val
        )?.OPTION_VAL,
      });
    },
    validationSchema: yup.object().shape({
      registration_number: yup
        .string()
        .required("Registration Number is required"),
      engine_number: yup
        .string()
        .required()
        .min(6, "Please enter a valid engine number")
        .max(17, "Please enter a valid engine number")
        .test(
          "engine_number",
          "Please enter a valid engine number",
          checkAlphanumeric
        ),
      chassis_number: yup
        .string()
        .required()
        .min(6, "Please enter a valid chassis number")
        .max(25, "Please enter a valid chassis number")
        .test(
          "chassis_number",
          "Please enter a valid chassis number",
          checkAlphanumeric
        ),
      prev_insurer_val: yup.string().required(),
      is_financed: yup.string().required(),
      prev_policy_no: yup.string().required(),
      financier_val: yup.string().when("is_financed", {
        is: "Y",
        then: yup.string().required(),
      }),
      ...(isOD && {
        tp_prev_insurer_val: yup.string().required(),
        tp_prev_policy_no: yup.string().required(),
        tp_existing_exp_date: yup
          .string()
          .required()
          .nullable()
          .test("dob", "Please enter a valid date", function (val) {
            return moment(val, "YYYY-MM-DD").isValid();
          }),
      }),
    }),
  });
  useEffect(() => {
    setValues({
      ...values,
      prev_insurer_name: proposalData.prev_insurer_name,
      prev_insurer_val: proposalData.prev_insurer_val,
      financier_name: proposalData.financier_name,
      financier_val: proposalData.financier_val,
      prev_policy_no: proposalData.prev_policy_no,
      is_financed: proposalData.is_financed || "N",
      registration_number: proposalData.registration_number,
      engine_number: proposalData.engine_number,
      chassis_number: proposalData.chassis_number,
      tp_prev_insurer_name: proposalData.tp_prev_insurer_name,
      tp_prev_insurer_val: proposalData.tp_prev_insurer_val,
      tp_prev_policy_no: proposalData.tp_prev_policy_no,
      tp_existing_exp_date: proposalData.tp_existing_exp_date,
    });
  }, [proposalData]);
  useEffect(() => {
    if (!proposalData.tp_existing_exp_date) {
      setFieldValue(
        "tp_existing_exp_date",
        moment(quote_data?.registration_date, "YYYY-MM-DD")
          .add(5, "years")
          .subtract(1, "days")
          .format("YYYY-MM-DD")
      );
    }
    if (!proposalData.registration_number) {
      setFieldValue("registration_number", quote_data?.rto_number);
    }
  }, [quote_data, proposalData, setFieldValue]);

  return (
    <>
      {isSubmitting && <Bike />}
      <form onSubmit={handleSubmit}>
        <Stack
          sx={{
            mt: 2,
          }}
          spacing={2}
        >
          <StyledInput
            label='Registration Number'
            size={"small"}
            onChange={(e) => {
              allowRegistrationNumber(e);
              handleChange(e);
            }}
            fixedPart={quote_data?.rto_number}
            name='registration_number'
            onBlur={handleBlur}
            value={values.registration_number ? values.registration_number : ""}
            error={
              touched.registration_number && Boolean(errors.registration_number)
            }
            helperText={
              touched.registration_number && errors.registration_number
            }
          />
          <StyledInput
            label='Engine Number'
            size={"small"}
            onChange={(e) => {
              noSpecialExceptHyphen(e);
              handleChange(e);
            }}
            name='engine_number'
            inputProps={{
              maxLength: 17,
            }}
            value={values.engine_number}
            onBlur={handleBlur}
            error={touched.engine_number && Boolean(errors.engine_number)}
            helperText={touched.engine_number && errors.engine_number}
          />

          <StyledInput
            label='Chassis Number'
            size={"small"}
            onChange={(e) => {
              noSpecialExceptHyphen(e);
              handleChange(e);
            }}
            name='chassis_number'
            value={values.chassis_number}
            onBlur={handleBlur}
            inputProps={{
              maxLength: 25,
            }}
            error={touched.chassis_number && Boolean(errors.chassis_number)}
            helperText={touched.chassis_number && errors.chassis_number}
          />
          <Select
            label='Previous Policy Insurer'
            size={"small"}
            handleChange={(value) => {
              setFieldValue("prev_insurer_val", value);
            }}
            selectedValue={values.prev_insurer_val}
            options={insurer_list || []}
            name='city'
            onBlur={handleBlur}
            error={touched.prev_insurer_val && Boolean(errors.prev_insurer_val)}
            helperText={touched.prev_insurer_val && errors.prev_insurer_val}
          />
          <StyledInput
            label='Previous Policy Number'
            size={"small"}
            onChange={handleChange}
            name='prev_policy_no'
            value={values.prev_policy_no}
            onBlur={handleBlur}
            error={touched.prev_policy_no && Boolean(errors.prev_policy_no)}
            helperText={touched.prev_policy_no && errors.prev_policy_no}
          />
          {isOD && (
            <>
              <Select
                label='Previous TP Policy Insurer'
                size={"small"}
                handleChange={(value) => {
                  setFieldValue("tp_prev_insurer_val", value);
                }}
                selectedValue={values.tp_prev_insurer_val}
                options={insurer_list || []}
                name='city'
                onBlur={handleBlur}
                error={
                  touched.tp_prev_insurer_val &&
                  Boolean(errors.tp_prev_insurer_val)
                }
                helperText={
                  touched.tp_prev_insurer_val && errors.tp_prev_insurer_val
                }
              />
              <StyledInput
                label='Previous TP Policy Number'
                size={"small"}
                onChange={handleChange}
                name='tp_prev_policy_no'
                value={values.tp_prev_policy_no}
                onKeyDown={() => {}}
                onBlur={handleBlur}
                error={
                  touched.tp_prev_policy_no && Boolean(errors.tp_prev_policy_no)
                }
                helperText={
                  touched.tp_prev_policy_no && errors.tp_prev_policy_no
                }
              />
              <DatePicker
                label='TP Policy Expiry Date'
                size={"small"}
                onChange={(val) => {
                  setFieldValue(
                    "tp_existing_exp_date",
                    moment(val).format("YYYY-MM-DD")
                  );
                }}
                minDate={moment(
                  moment(quote_data?.registration_date, "YYYY-MM-DD")
                    .add(5, "years")
                    .subtract(1, "days")
                    .subtract(45, "days")
                )}
                maxDate={moment(
                  moment(quote_data?.registration_date, "YYYY-MM-DD")
                    .add(5, "years")
                    .subtract(1, "days")
                    .add(45, "days")
                )}
                value={values.tp_existing_exp_date}
                name='tp_existing_exp_date'
                onBlur={handleBlur}
                error={
                  touched.tp_existing_exp_date &&
                  Boolean(errors.tp_existing_exp_date)
                }
                helperText={
                  touched.tp_existing_exp_date && errors.tp_existing_exp_date
                }
              />
            </>
          )}
          <Box>
            <Typography variant='subtitle1' component={"p"}>
              Is your vehicle financed?
            </Typography>
            <RadioGroup
              name='is_financed'
              sx={{
                flexDirection: "row",
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.is_financed}
            >
              <FormControlLabel control={<Radio />} label='Yes' value={"Y"} />
              <FormControlLabel control={<Radio />} label='No' value={"N"} />
            </RadioGroup>
          </Box>
          {values.is_financed === "Y" && (
            <>
              <Select
                label='Select Your Financier'
                size={"small"}
                handleChange={(value) => {
                  setFieldValue("financier_val", value);
                }}
                selectedValue={values.financier_val}
                options={financier_list || []}
                name='city'
                onBlur={handleBlur}
                error={touched.financier_val && Boolean(errors.financier_val)}
                helperText={touched.financier_val && errors.financier_val}
              />
            </>
          )}
          <StyledButton type='submit' variant='contained' size='medium'>
            {(product_data?.INSURANCE_SLUG === "godigit" || product_data?.INSURANCE_SLUG === "icici_lombard_general")
              ? 
              "Proceed to CKYC "
              : "Proceed to Final Submit"}
          </StyledButton>
        </Stack>
      </form>
    </>
  );
};
const SummaryComp: React.FC = () => {
  return <></>;
};

const VehicleDetails = () => {
  return (
    <>
      <FormCard
        title='Vehicle Details'
        index={3}
        EditComp={EditComp}
        SummaryComp={SummaryComp}
      />
    </>
  );
};

export default VehicleDetails;
