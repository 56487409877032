import { Close } from "@mui/icons-material";
import { Breakpoint, Dialog, Divider } from "@mui/material";
import React from "react";
import styled from "styled-components";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  maxWidth: Breakpoint;
  fullScreen?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  open,
  onClose,
  title,
  maxWidth,
  ...props
}) => {
  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      onClose={onClose}
      fullWidth
      disableEnforceFocus
      {...props}
    >
      {title && (
        <>
          <DialogTitle>
            <div>{title}</div>
            <CloseButton onClick={() => onClose()}>
              <Close />
            </CloseButton>
          </DialogTitle>
          <Divider />
        </>
      )}
      {props.children}
    </Dialog>
  );
};

export default Modal;

const DialogTitle = styled.div`
  display: flex;
  padding: 16px 12px;
  justify-content: space-between;
  align-items: center;
  & div {
    font-weight: 600;
    font-size: 18px;
  }
`;
const CloseButton = styled.button`
  background: transparent;
  border: none;
  color: var(--dark-grey-2);
  display: flex;
  align-items: center;
  &:focus {
    outline: none;
  }
`;
