import styled from "styled-components";

export const Form = styled.form`
  min-width: 440px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(212, 236, 243, 0.83);
  padding: 30px 30px;
  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
    box-shadow: none;
    min-width: unset;
  }
`;

export const FormHeading = styled.p`
  font-weight: 700;
  font-size: 24px;
  color: var(--tertiary-color);
  margin-bottom: 0;
  & span {
    font-weight: 900;
    font-size: 28px;
    color: var(--secondary-color);
  }
  @media (max-width: 768px) {
    font-size: 20px;
    font-weight: 600;
    & span {
      font-size: 24px;
      font-weight: 600;
    }
  }
`;
export const SubText = styled.p`
  color: var(--dark-grey-1);
  margin-bottom: 40px;
  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 20px;
  }
`;
export const NoCarNumberButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 16px 0 32px;
  @media (max-width: 768px) {
    display: none;
  }
`;
