import { Container } from "react-bootstrap";
import React from "react";
import {
  useGetEligibleProductsQuery,
  useVerifyHash,
} from "../../services/CarServices";
import { StyledButton } from "../Button/Button";
import logo from "./../../assets/images/logo.svg";
import "./Header.scss";
import { Avatar, Menu, MenuItem, useMediaQuery } from "@mui/material";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
const Header = () => {
  useGetEligibleProductsQuery();
  const { isVerified, user } = useVerifyHash();
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const open = Boolean(anchorEl);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Container>
        <nav className='header'>
          <div className='header__logo-wrapper'>
            <img src={logo} alt='logo' className='header__logo' />
          </div>
          <ul className='header__list'>
            {!isMobile && (
              <>
                {" "}
                <li className='header__list-item'>
                  <a
                    href={`${process.env.REACT_APP_BASE_REDIRECT_URL}`}
                    target='_blank'
                  >
                    Home
                  </a>
                </li>
                <li className='header__list-item'>
                  <a
                    href={`${process.env.REACT_APP_BASE_REDIRECT_URL}/about-us`}
                    target='_blank'
                  >
                    About Us
                  </a>
                </li>
                <li className='header__list-item'>
                  <a
                    href='https://play.google.com/store/search?q=vkover&c=apps'
                    target='_blank'
                  >
                    Join As POSP
                  </a>
                </li>
                <li className='header__list-item'>
                  <a href='#' target='_blank'>
                    Contact Us
                  </a>
                </li>
                {isVerified ? (
                  <>
                    {" "}
                    <li>
                      <button
                        style={{
                          background: "none",
                          border: "none",
                          display: "inline-flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                      >
                        <Avatar>
                          {user?.FIRST_NAME?.charAt(0).toUpperCase()}
                        </Avatar>
                        <div>{user?.EMAIL_ID}</div>
                      </button>
                    </li>
                    <UserMenu
                      anchorEl={anchorEl}
                      open={open}
                      handleClose={handleClose}
                    />
                  </>
                ) : (
                  <>
                    <li>
                      <StyledButton variant='contained'>Login</StyledButton>
                    </li>
                    <li>
                      <StyledButton variant='outlined'>Sign Up</StyledButton>
                    </li>
                  </>
                )}
              </>
            )}
          </ul>
        </nav>
      </Container>
    </>
  );
};
const UserMenu: React.FC<{
  anchorEl: HTMLElement | null;
  open: boolean;
  handleClose: () => void;
}> = ({ anchorEl, open, handleClose = () => {} }) => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Menu
      id='basic-menu'
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <MenuItem
        onClick={() => {
          window.open("https://account.vkover.com/dashboard");
          handleClose();
        }}
      >
        My account
      </MenuItem>
      <MenuItem
        onClick={() => {
          const queryParams = new URLSearchParams(location.search);
          if (queryParams.has("hash")) {
            queryParams.delete("hash");
            navigate(
              {
                search: queryParams.toString(),
              },
              { replace: true }
            );
          }
          queryClient.invalidateQueries("auth");
          localStorage.removeItem("hash");
          handleClose();
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );
};
export default Header;
