import React from "react";
import { FormHeading } from "../Contact/Contact.style";
import { CompanyWrapper, ItemLink, List, ListItem } from "./Company.style";

const Company = () => {
  return (
    <CompanyWrapper>
      <FormHeading reduceMargin>Company</FormHeading>
      <List>
        <ListItem>
          <ItemLink href='https://play.google.com/store/search?q=vkover&c=apps'>
            Join as POSP
          </ItemLink>
        </ListItem>
        <ListItem>
          <ItemLink
            href={`${process.env.REACT_APP_BASE_REDIRECT_URL}/about-us`}
          >
            About us
          </ItemLink>
        </ListItem>
        <ListItem>
          <ItemLink
            href={`${process.env.REACT_APP_BASE_REDIRECT_URL}/privacy-policy`}
          >
            Privacy Policy
          </ItemLink>
        </ListItem>
        <ListItem>
          <ItemLink
            href={`${process.env.REACT_APP_BASE_REDIRECT_URL}/terms-and-conditions`}
          >
            Terms of Use
          </ItemLink>
        </ListItem>
        <ListItem>
          <ItemLink>Contact</ItemLink>
        </ListItem>
      </List>
    </CompanyWrapper>
  );
};

export default Company;
