import styled from "styled-components";

interface TextButtonProps {
  color: "primary" | "secondary" | "tertiary";
  fullWidth?: boolean;
}
const TextButton = styled.button<TextButtonProps>`
  background: none;
  border: none;
  font-weight: 600;
  width: ${(props) => props.fullWidth && "100%"};
  color: ${(props) => {
    switch (props.color) {
      case "primary":
        return "var(--primary-color)";
      case "secondary":
        return "var(--secondary-color)";
      case "tertiary":
      default:
        return "var(--dark-grey-1)";
    }
  }};

  &:hover {
    color: var(--primary-color);
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export default TextButton;
