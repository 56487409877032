import React from "react";
import styled from "styled-components";
interface DetailsProps {
  label: string;
  value: string;
}
const Details: React.FC<DetailsProps> = ({ label, value }) => {
  return (
    <Wrapper>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </Wrapper>
  );
};
export const MainDetails: React.FC<DetailsProps> = ({ label, value }) => {
  return (
    <Wrapper>
      <Label main>{label}</Label>
      <Value main>{value}</Value>
    </Wrapper>
  );
};
export default Details;
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 12px;
`;
const Label = styled.div<{
  main?: boolean;
}>`
  font-size: ${(props) => (props.main ? "16px" : "14px")};
  font-weight: ${(props) => (props.main ? "700" : "400")};
  color: ${(props) =>
    props.main ? "var(--tertiary-color)" : "var(--dark-grey-1)"};
`;
const Value = styled.div<{
  main?: boolean;
}>`
  font-size: ${(props) => (props.main ? "20px" : "14px")};
  font-weight: ${(props) => (props.main ? "700" : "600")};
  color: ${(props) =>
    props.main ? "var(--secondary-color)" : "var(--tertiary-color)"};
`;
