import React from "react";

import { EditWrapper } from "./EditDetails.style";
import ExistingPolicyDetails from "./ExistingPolicyDetails/ExistingPolicyDetails";
import VehicleDetails from "./VehicleDetails/VehicleDetails";

const EditDetails = () => {
  return (
    <>
      <EditWrapper>
        <VehicleDetails />
        <ExistingPolicyDetails />
      </EditWrapper>
    </>
  );
};

export default EditDetails;
