import React from "react";
import {
  ContactWrapper,
  FormHeading,
  FormWrapper,
  LogoWrapper,
  TextInput,
} from "./Contact.style";
import vkover from "./../../../../assets/images/vkover.png";
import { Button } from "@mui/material";

const Contact = () => {
  return (
    <ContactWrapper>
      <LogoWrapper>
        <img alt='company-logo' src={vkover} />
      </LogoWrapper>
      <FormWrapper>
        <FormHeading noValidate>Contact Us</FormHeading>
        <TextInput type='text' placeholder='Name' />
        <TextInput type='email' placeholder='Email' />
        <TextInput type='tel' placeholder='Phone Number' />
        <Button variant='contained' fullWidth>
          Send Message
        </Button>
      </FormWrapper>
    </ContactWrapper>
  );
};

export default Contact;
