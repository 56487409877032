import { Divider, Paper, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import StyledInput from "../../../../components/TextInput/TextInput";
import { onInputPhone } from "../../../../utils";
import styled from "styled-components";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useGaragesQuery } from "../../../../services/QuoteServices";
const CashlessGarages = () => {
  const [pincode, setPincode] = useState("");
  const {
    quoteData: { product_data },
  } = useTypedSelector((state) => state.quote.see_details);
  const { garages, isLoading } = useGaragesQuery({
    pincode,
    company_slug: product_data?.INSURANCE_SLUG,
  });
  return (
    <>
      <Paper
        sx={{
          width: "70%",
          padding: "24px 16px",
          background: "rgb(255, 255, 255)",
          boxShadow: "rgb(0 0 0 / 2%) 0px 4px 8px",
          borderRadius: "3px",
          height: "360px",
          overflowY: "auto",
          margin: "auto",
        }}
      >
        <StyledInput
          label='Search by Pincode'
          value={pincode}
          onChange={(e) => {
            onInputPhone(e);
            setPincode(e.target.value);
          }}
          inputProps={{
            maxLength: 6,
          }}
        />
        <Typography
          variant='subtitle1'
          sx={{
            color: "var(--dark-grey-1)",
            fontSize: "15px",
          }}
        >
          {garages.length} garages found in your area
        </Typography>
        <Stack spacing={2} divider={<Divider />}>
          {garages ? (
            garages.map((item) => (
              <>
                <GarageCard
                  name={item.WorkshopName}
                  address={item.Address}
                  phone={item.Phone}
                />
              </>
            ))
          ) : (
            <></>
          )}
        </Stack>
      </Paper>
    </>
  );
};

export default CashlessGarages;
const GarageCard = ({
  name,
  address,
  phone,
}: {
  name: string;
  address: string;
  phone: string;
}) => {
  return (
    <>
      <Wrapper>
        <LeftWrapper>
          <Name>{name}</Name>
          <Address>
            <LocationOnIcon
              sx={{
                width: "16px",
                height: "16px",
                marginTop: "-2px",
              }}
            />
            {"  "} {address}
          </Address>
        </LeftWrapper>
        <Phone>
          <PhoneIcon
            sx={{
              width: "16px",
              height: "16px",
              marginTop: "-2px",
            }}
          />
          {phone}
        </Phone>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
  align-items: flex-end;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 70%;
`;
const Address = styled.div`
  color: var(--dark-grey-2);
  font-weight: 600;
  font-size: 14px;
  @media (max-width: 576px) {
    font-size: 12px;
  }
`;
const Phone = styled(Address)``;
const Name = styled.div`
  font-weight: 600;
  font-size: 20px;
  @media (max-width: 576px) {
    font-size: 16px;
  }
`;
