import { FormControlLabel, FormGroup, Checkbox as Check } from "@mui/material";
import React from "react";
interface CheckboxProps {
  label: string;
  onChange: (checked: boolean) => void;
  checked: boolean;
}
const Checkbox: React.FC<CheckboxProps> = ({ label, onChange, checked }) => {
  return (
    <>
      <FormGroup>
        <FormControlLabel
          sx={{
            "& .MuiFormControlLabel-label": {
              marginLeft: "12px",
              "@media(max-width:768px)": {
                marginLeft: "6px",
                fontSize: "12px",
              },
            },
            "@media(max-width:768px)": {
              marginLeft: "6px",
              marginRight: "6px",
            },
          }}
          control={
            <Check
              sx={{
                padding: 0,
                "@media(max-width:768px)": {
                  "& svg": {
                    width: "18px",
                    height: "18px",
                  },
                },
              }}
            />
          }
          checked={checked}
          label={label}
          value='Y'
          onChange={(e, checked) => onChange(checked)}
        />
      </FormGroup>
    </>
  );
};

export default Checkbox;
