import styled from "styled-components";

export const QuotesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
  gap: 25px;
  @media (max-width: 768px) {
    padding: 12px 0;
    gap: 12px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
  width: calc(25% - 20px);
  gap: 20px;
  border: 1px solid var(--light-grey-2);
  border-radius: 4px;
  & img {
    height: 60px;
    width: auto;
  }
  transition: box-shadow 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 4px 12px rgba(0, 110, 144, 0.16);
  }
  & > button {
    margin-top: 20px;
  }
  margin-bottom: 20px;
  @media (max-width: 768px) {
    width: calc(50% - 12px);
    padding: 20px 12px 12px;
    gap: 6px;
  }
`;
export const AddonsData = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  min-height: 24px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 6px;
    margin-top: 6px;
    align-items: center;
  }
`;

export const QuoteData = styled.div`
  display: flex;
  justify-content: space-evenly;
  font-size: 18px;
`;
