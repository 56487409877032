import styled from "styled-components";

export const FiltersWrapper = styled.div`
  border: 0.5px solid #eaf1f6;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 20px;
  @media (max-width: 768px) {
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 8px;
  }
`;
export const Filter = styled.div<{ idv?: boolean }>`
  flex: ${(props) => (props.idv ? 1.5 : 1)};
  display: inline-flex;
  font-size: 18px;
  align-items: center;
  position: relative;
  justify-content: space-evenly;
  padding: 12px 0;
  transition: box-shadow 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 4px 12px rgba(0, 110, 144, 0.16);
  }
  &:not(:last-child):after {
    content: "";
    height: 20px;
    position: absolute;
    right: -2px;
    width: 1px;
    background: var(--light-grey-2);
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
  }
`;
export const FilterName = styled.div`
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
export const FilterValue = styled.div`
  color: var(--tertiary-color);
  @media (max-width: 768px) {
    font-size: 12px;
    font-weight: 600;
  }
`;
