import { Grid, Stack } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { lowercase, onInputName, onInputPhone } from "../../utils";
import Modal from "../Modal/Modal";
import StyledInput from "../TextInput/TextInput";
import * as yup from "yup";
import { StyledButton } from "../Button/Button";
import { useLocation } from "react-router-dom";
import {
  useSendPaymentMutation,
  useSendProposalMutation,
  useSendQuoteMutation,
} from "../../services/ShareServices";
import { useGetQuotes } from "../../services/QuoteServices";
interface ShareDialogProps {
  open: boolean;
  onClose: () => void;
  type: "MAIL" | "WHTS" | false;
}

const ShareDialog: React.FC<ShareDialogProps> = ({ open, onClose, type }) => {
  const location = useLocation();
  const { sendQuoteEmail } = useSendQuoteMutation();
  const { sendProposalEmail } = useSendProposalMutation();
  const { sendPaymentEmail } = useSendPaymentMutation();
  const { fetchedQuotes } = useGetQuotes();
  const { handleChange, handleBlur, handleSubmit, errors, touched, values } =
    useFormik({
      initialValues: {
        first_name: "",
        last_name: "",
        email: "",
        mobile_no: "",
      },
      onSubmit: (values) => {
        if (location.pathname === "/quotes")
          sendQuoteEmail({ ...values, quotes: fetchedQuotes, type });
        if (location.pathname === "/proposal")
          sendProposalEmail({ ...values, type });
        if (location.pathname === "/summary")
          sendPaymentEmail({ ...values, type });
      },
      validationSchema: yup.object().shape({
        first_name: yup.string().required("First name is required"),
        last_name: yup.string().required("Last name is required"),
        ...(type === "MAIL" && {
          email: yup
            .string()
            .required("Email is required")
            .email("Please enter a valid email"),
        }),
        ...(type === "WHTS" && {
          mobile_no: yup
            .string()
            .required("Mobile number is required")
            .matches(/^[5-9]{1}[0-9]{9}$/, "Please enter a valid number"),
        }),
      }),
    });
  return (
    <Modal open={open} onClose={onClose} maxWidth={"sm"} title='Share Details'>
      <form
        onSubmit={handleSubmit}
        style={{
          padding: "32px",
        }}
      >
        <Stack spacing={2}>
          <StyledInput
            label='First Name'
            size={"small"}
            onChange={(e) => {
              onInputName(e);
              handleChange(e);
            }}
            name='first_name'
            onBlur={handleBlur}
            value={values.first_name}
            error={touched.first_name && Boolean(errors.first_name)}
            helperText={touched.first_name && errors.first_name}
          />
          <StyledInput
            label='Last Name'
            size={"small"}
            onChange={(e) => {
              onInputName(e);
              handleChange(e);
            }}
            name='last_name'
            value={values.last_name}
            onBlur={handleBlur}
            error={touched.last_name && Boolean(errors.last_name)}
            helperText={touched.last_name && errors.last_name}
          />

          <StyledInput
            label='Email Address'
            size={"small"}
            onChange={(e) => {
              lowercase(e);
              handleChange(e);
            }}
            name='email'
            value={values.email}
            onBlur={handleBlur}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
          />
          <StyledInput
            label='Mobile Number'
            size={"small"}
            onChange={(e) => {
              onInputPhone(e);
              handleChange(e);
            }}
            name='mobile_no'
            value={values.mobile_no}
            onBlur={handleBlur}
            inputProps={{
              maxLength: 10,
            }}
            error={touched.mobile_no && Boolean(errors.mobile_no)}
            helperText={touched.mobile_no && errors.mobile_no}
          />
        </Stack>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={6} md={6}>
            <StyledButton
              variant='outlined'
              fullWidth
              size='medium'
              onClick={onClose}
            >
              Close
            </StyledButton>
          </Grid>

          <Grid item xs={6} md={6}>
            <StyledButton
              type='submit'
              fullWidth
              variant='contained'
              size='medium'
            >
              Share
            </StyledButton>
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
};

export default ShareDialog;
