import ArrowBack from "@mui/icons-material/ArrowBack";
import { IconButton, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { StyledButton } from "../../../../../components/Button/Button";
import useSearch from "../../../../../hooks/useSearch";
import { setActiveVehicleDetails, setBrand } from "../../../../../modules";
import {
  BrandsInterface,
  useGetBrandsQuery,
} from "../../../../../services/CarServices";
import { InputWrapper, Question, TextInput } from "../VehicleDetails.style";
import search from "./../../../../../assets/images/search.png";
import {
  ExpandableBox,
  ExpandButtonWrapper,
  ModelWrapper,
} from "./Forms.style";
import MobileSectionLabel from "./MobileSectionLabel";
const Brand = () => {
  const [expand, setExpand] = useState(false);
  const dispatch = useDispatch();
  const { brandsList } = useGetBrandsQuery();
  const { searchResults, searchText, onSearch } = useSearch<
    keyof BrandsInterface
  >("BRAND_TITLE", brandsList);
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <>
      {isMobile && (
        <MobileSectionLabel label='Select your Bike Brand' onClick={() => {}} />
      )}
      <Question>
        What is your bike's <span>Brand</span> ?
      </Question>
      <InputWrapper>
        <TextInput
          id='search'
          placeholder='Search your bike brand here'
          onChange={onSearch}
          value={searchText}
        />
        <label htmlFor='search'>
          <img src={search} alt='search'></img>
        </label>
      </InputWrapper>
      <ExpandableBox expand={expand}>
        {searchResults?.map((brand) => (
          <ModelWrapper
            onClick={() => {
              dispatch(setActiveVehicleDetails(1));
              dispatch(
                setBrand({ id: brand.BRAND_ID, title: brand.BRAND_TITLE })
              );
            }}
          >
            {brand.BRAND_TITLE}
          </ModelWrapper>
        ))}
      </ExpandableBox>
      {!expand && (
        <ExpandButtonWrapper>
          <StyledButton
            variant='outlined'
            fullWidth
            onClick={() => {
              setExpand(true);
            }}
          >
            View all bikes
          </StyledButton>
        </ExpandButtonWrapper>
      )}
    </>
  );
};

export default Brand;
