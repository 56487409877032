import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { removeAddons, setAddOns } from "../modules/quotes.slice";
import { useTypedSelector } from "./useTypedSelector";
import useUrlQuery from "./useUrlQuery";

const useFilters = () => {
  const { getUrlQuery } = useUrlQuery();
  const addOnsParam = getUrlQuery("addOns") || 0;
  const dispatch = useDispatch();
  const { addOns } = useTypedSelector((state) => state.quote);
  useEffect(() => {
    function dec2bin(dec: number) {
      return (dec >>> 0).toString(2);
    }
    const addOnsBin = dec2bin(Number(addOnsParam));
    const addOnsArray = addOnsBin
      .split("")
      .map((item) => Number(item))
      .reverse();

    if (addOnsArray[2]) {
      dispatch(setAddOns("PA_COVER"));
    } else dispatch(removeAddons("PA_COVER"));
    if (addOnsArray[1]) {
      dispatch(setAddOns("NILL_DEPT"));
    } else dispatch(removeAddons("NILL_DEPT"));
  }, [addOnsParam, dispatch]);
  return { selectedAddonsParam: Number(addOnsParam) };
};

export default useFilters;
