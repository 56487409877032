import { Autocomplete } from "@mui/material";
import React, { useEffect, useState } from "react";
import StyledInput from "../TextInput/TextInput";
export type SelectOptions = { OPTION_KEY: string; OPTION_VAL: string };

interface SelectProps {
  enlarge?: boolean;
  label: string;
  error?: boolean | undefined;
  selectedValue?: string;
  handleChange?: (val: string) => void;
  options: SelectOptions[];
  name?: string;
  onBlur?: (e: any) => void;
  size?: "small" | "medium";
  helperText?: string | false;
}
const StyledSelect = ({
  selectedValue,
  handleChange,
  ...props
}: SelectProps) => {
  const [value, setValue] = useState<SelectOptions | null>(null);
  const [inputValue, setInputValue] = useState<string | undefined>("");

  useEffect(() => {
    if (selectedValue) {
      const x = props.options.find((item) => item.OPTION_KEY === selectedValue);
      setValue(x || null);
      setInputValue(x?.OPTION_KEY || "");
    } else {
      setValue(null);
      setInputValue("");
    }
  }, [selectedValue, props.options]);

  const onInputChange = (e: any, newValue: string) => {
    setInputValue(newValue);
  };
  const onChange = (e: any, value: SelectOptions | null) => {
    setValue(value);
    if (!value) {
      console.error("value undefined in onchange of autocomplete");
      return;
    }
    handleChange && handleChange(value.OPTION_KEY);
  };
  return (
    <Autocomplete
      value={value}
      inputValue={inputValue}
      options={props.options}
      clearOnBlur
      blurOnSelect
      onInputChange={onInputChange}
      onChange={onChange}
      getOptionLabel={(option) => option.OPTION_VAL}
      isOptionEqualToValue={(option, value) =>
        option.OPTION_KEY === value.OPTION_VAL
      }
      size={props.size}
      onBlur={props.onBlur}
      renderInput={(params) => (
        <StyledInput
          label={props.label}
          name={props.name}
          error={props.error}
          helperText={props.helperText}
          {...params}
        />
      )}
    />
  );
};

export default React.memo(StyledSelect);
