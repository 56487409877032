import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as yup from "yup";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import FormCard from "../FormCard";
import StyledInput from "./../../../../components/TextInput/TextInput";
import { StyledButton } from "../../../../components/Button/Button";
import Select from "../../../../components/Select/Select";
import {
  useGetPincodeMutation,
  useGetProposalDetailsQuery,
  useUpdateProposalMutation,
} from "../../../../services/ProposalServices";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { nextForm } from "../../../../modules/proposal.slice";
import { useDispatch } from "react-redux";
import { onInputPhone } from "../../../../utils";
// import { values } from "lodash";

interface CommunicationData {
  pincode: string;
  address: string;
  city_name: string;
  city_val: string;
  state_val: string;
  state_name: string;
  is_reg_same_as_com: string;
  reg_pincode: string;
  reg_address: string;
  reg_city_name: string;
  reg_city_val: string;
  reg_state_name: string;
  reg_state_val: string;
}
const EditComp: React.FC = () => {
  const dispatch = useDispatch();
  const { proposal_data } = useGetProposalDetailsQuery();
  const isReliance = proposal_data?.COMPANY_SLUG === "reliance";
  const { updateProposal } = useUpdateProposalMutation();
  const { proposalData } = useTypedSelector((state) => state.proposal);
  const { getCityState, cityOptions, stateOptions, isCityStateFetched } =
    useGetPincodeMutation();
  const {
    getCityState: getRegCityState,
    cityOptions: regCityOptions,
    stateOptions: regStateOptions,
    isCityStateFetched: isRegCityStateFetched,
  } = useGetPincodeMutation();
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    touched,
    errors,
    setFieldValue,
    setValues,
  } = useFormik<CommunicationData>({
    enableReinitialize: true,
    initialValues: {
      pincode: "",
      address: "",
      city_name: "",
      city_val: "",
      state_name: "",
      state_val: "",
      is_reg_same_as_com: "Y",
      reg_pincode: "",
      reg_address: "",
      reg_city_name: "",
      reg_city_val: "",
      reg_state_name: "",
      reg_state_val: "",
    },
    onSubmit: (values) => {
      dispatch(nextForm());
      updateProposal({
        ...proposalData,
        ...values,
        city_name: cityOptions?.find(
          (city) => city.OPTION_KEY === values.city_val
        )?.OPTION_VAL,
        reg_city_name: regCityOptions?.find(
          (city) => city.OPTION_KEY === values.city_val
        )?.OPTION_VAL,
        ...(isReliance &&
          values.is_reg_same_as_com === "Y" && {
            reg_pincode: values.pincode,
            reg_address: values.address,
            reg_city_name: values.city_name,
            reg_city_val: values.city_val,
            reg_state_name: values.state_name,
            reg_state_val: values.state_val,
          }),
      });
    },
    validationSchema: yup.object().shape({
      pincode: yup
        .string()
        .required("Pincode is required")
        .matches(/^[1-6]{1}[0-9]{5}$/, "Please enter a valid pincode"),
      address: yup
        .string()
        .required("Address is required")
        .min(3, "Please enter a valid address"),
      city_val: yup.string().required("City is required"),
      state_name: yup.string().required("State is required"),
      ...(isReliance && {
        is_reg_same_as_com: yup.string(),
        reg_pincode: yup.string().when("is_reg_same_as_com", {
          is: "N",
          then: yup
            .string()
            .required("Pincode is required")
            .matches(/^[1-6]{1}[0-9]{5}$/, "Please enter a valid pincode"),
        }),
        reg_address: yup.string().when("is_reg_same_as_com", {
          is: "N",
          then: yup
            .string()
            .required("Address is required")
            .min(3, "Please enter a valid address"),
        }),
        reg_city_val: yup.string().when("is_reg_same_as_com", {
          is: "N",
          then: yup.string().required("City is required"),
        }),
        reg_state_name: yup.string().when("is_reg_same_as_com", {
          is: "N",
          then: yup.string().required("State is required"),
        }),
      }),
    }),
  });
  useEffect(() => {
    if (values.pincode && values.pincode.length === 6) {
      getCityState({
        pincode: values.pincode,
      });
    }
  }, [values.pincode]);
  useEffect(() => {
    if (values.reg_pincode && values.reg_pincode.length === 6) {
      getRegCityState({
        pincode: values.reg_pincode,
      });
    }
  }, [values.reg_pincode]);
  useEffect(() => {
    if (isCityStateFetched && stateOptions) {
      setFieldValue("state_name", stateOptions[0].OPTION_VAL);
      setFieldValue("state_val", stateOptions[0].OPTION_KEY);
    }
  }, [isCityStateFetched, stateOptions]);
  useEffect(() => {
    if (isRegCityStateFetched && regStateOptions) {
      setFieldValue("state_name", regStateOptions[0].OPTION_VAL);
      setFieldValue("state_val", regStateOptions[0].OPTION_KEY);
    }
  }, [isRegCityStateFetched, regStateOptions]);
  useEffect(() => {
    setValues({
      ...values,
      city_name: proposalData.city_name,
      city_val: proposalData.city_val,
      state_name: proposalData.state_name,
      state_val: proposalData.state_val,
      address: proposalData.address,
      pincode: proposalData.pincode,
      is_reg_same_as_com: proposalData.is_reg_same_as_com || "Y",
      reg_pincode: proposalData.reg_pincode,
      reg_address: proposalData.reg_address,
      reg_city_name: proposalData.reg_city_name,
      reg_city_val: proposalData.reg_city_val,
      reg_state_name: proposalData.reg_state_name,
      reg_state_val: proposalData.reg_state_val,
    });
  }, [proposalData]);
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Stack
          sx={{
            mt: 2,
          }}
          spacing={2}
        >
          <StyledInput
            label='Pincode'
            size={"small"}
            onChange={(e) => {
              onInputPhone(e);
              handleChange(e);
            }}
            inputProps={{
              maxLength: 6,
            }}
            name='pincode'
            onBlur={handleBlur}
            value={values.pincode}
            error={touched.pincode && Boolean(errors.pincode)}
            helperText={touched.pincode && errors.pincode}
          />

          <StyledInput
            label='Address'
            size={"small"}
            onChange={handleChange}
            name='address'
            value={values.address}
            onBlur={handleBlur}
            error={touched.address && Boolean(errors.address)}
            helperText={touched.address && errors.address}
          />
          <Select
            label='City'
            size={"small"}
            handleChange={(value) => {
              setFieldValue("city_val", value);
            }}
            selectedValue={values.city_val}
            options={cityOptions || []}
            name='city'
            onBlur={handleBlur}
            error={touched.city_val && Boolean(errors.city_val)}
            helperText={touched.city_val && errors.city_val}
          />
          <StyledInput
            label='State'
            size={"small"}
            inputProps={{
              readOnly: true,
            }}
            onChange={handleChange}
            name='state'
            value={values.state_name}
            onBlur={handleBlur}
            error={touched.state_name && Boolean(errors.state_name)}
            helperText={touched.state_name && errors.state_name}
          />
          <>
            {isReliance && (
              <>
                <Box>
                  <Typography variant='subtitle1' component={"p"}>
                    Is registration address same as communication address
                  </Typography>
                  <RadioGroup
                    name='is_reg_same_as_com'
                    sx={{
                      flexDirection: "row",
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.is_reg_same_as_com}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label='Yes'
                      value={"Y"}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label='No'
                      value={"N"}
                    />
                  </RadioGroup>
                </Box>
                {values.is_reg_same_as_com === "N" && (
                  <>
                    <StyledInput
                      label='Registration Pincode'
                      size={"small"}
                      onChange={(e) => {
                        onInputPhone(e);
                        handleChange(e);
                      }}
                      inputProps={{
                        maxLength: 6,
                      }}
                      name='reg_pincode'
                      onBlur={handleBlur}
                      value={values.reg_pincode}
                      error={touched.reg_pincode && Boolean(errors.reg_pincode)}
                      helperText={touched.reg_pincode && errors.reg_pincode}
                    />

                    <StyledInput
                      label='Registration Address'
                      size={"small"}
                      onChange={handleChange}
                      name='reg_address'
                      value={values.reg_address}
                      onBlur={handleBlur}
                      error={touched.reg_address && Boolean(errors.reg_address)}
                      helperText={touched.reg_address && errors.reg_address}
                    />
                    <Select
                      label='Registration City'
                      size={"small"}
                      handleChange={(value) => {
                        setFieldValue("reg_city_val", value);
                      }}
                      selectedValue={values.reg_city_val}
                      options={regCityOptions || []}
                      name='city'
                      onBlur={handleBlur}
                      error={
                        touched.reg_city_val && Boolean(errors.reg_city_val)
                      }
                      helperText={touched.reg_city_val && errors.reg_city_val}
                    />
                    <StyledInput
                      label='Registration State'
                      size={"small"}
                      inputProps={{
                        readOnly: true,
                      }}
                      onChange={handleChange}
                      name='state'
                      value={values.reg_state_name}
                      onBlur={handleBlur}
                      error={
                        touched.reg_state_name && Boolean(errors.reg_state_name)
                      }
                      helperText={
                        touched.reg_state_name && errors.reg_state_name
                      }
                    />
                  </>
                )}
              </>
            )}
          </>
          <StyledButton type='submit' variant='contained' size='medium'>
            Proceed to Vehicle
          </StyledButton>
        </Stack>
      </form>
    </>
  );
};
const SummaryComp: React.FC = () => {
  return <></>;
};
//Mui Gender Radio component
const CommunicationDetails = () => {
  return (
    <>
      <FormCard
        title='Communication Details'
        index={2}
        EditComp={EditComp}
        SummaryComp={SummaryComp}
      />
    </>
  );
};

export default CommunicationDetails;
