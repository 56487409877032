import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as yup from "yup";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import FormCard from "../FormCard";
import StyledInput from "../../../../components/TextInput/TextInput";
import { StyledButton } from "../../../../components/Button/Button";
import { useDispatch } from "react-redux";
import { nextForm } from "../../../../modules/proposal.slice";
import DatePicker from "../../../../components/DatePicker/DatePicker";
import moment from "moment";
import {
  useGetOccupationQuery,
  useGetProposalDetailsQuery,
  useGetRelationMasterQuery,
  useUpdateProposalMutation,
} from "../../../../services/ProposalServices";
import Select from "../../../../components/Select/Select";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
// import { values } from "lodash";

interface PersonalData {
  gender: "M" | "F";
  dob: string | null;
  is_married: "Y" | "N";
  occupation: string;
  nominee_dob: string | null;
  nominee_relation: string;
  nominee_name: string;
  nominee_age?: number;
}
const EditComp: React.FC = () => {
  const dispatch = useDispatch();
  const { occupation_list } = useGetOccupationQuery();
  const { relations } = useGetRelationMasterQuery();
  const { updateProposal } = useUpdateProposalMutation();
  const { proposalData } = useTypedSelector((state) => state.proposal);
  const { proposal_data } = useGetProposalDetailsQuery();
  const is_pa_selected = proposal_data?.ADDONS.some(
    (item) => item.slug === "PA_COVER"
  );

  const {
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    values,
    touched,
    errors,
    setValues,
  } = useFormik<PersonalData>({
    enableReinitialize: true,
    initialValues: {
      gender: "M",
      dob: null,
      is_married: "N",
      occupation: "",
      nominee_dob: null,
      nominee_name: "",
      nominee_relation: "",
    },
    onSubmit: (values) => {
      dispatch(nextForm());
      updateProposal({
        ...proposalData,
        ...values,
        nominee_age: moment().diff(moment(values.nominee_dob), "years"),
        occupation_val: occupation_list?.find(
          (item) => item.OPTION_KEY === values.occupation
        )?.OPTION_VAL,
      });
    },
    validationSchema: yup.object().shape({
      ...(proposalData?.owner_type === "IND" && {
        dob: yup
          .string()
          .nullable()
          .required("Date of Birth is required")
          .test("dob", "Please enter a valid date", function (val) {
            return moment(val, "YYYY-MM-DD").isValid();
          }),
      }),
      occupation: yup.string().required(),
      ...(is_pa_selected && {
        nominee_dob: yup
          .string()
          .nullable()
          .required("Nominee's Date of Birth is required")
          .test("dob", "Please enter a valid date", function (val) {
            return moment(val, "YYYY-MM-DD").isValid();
          })
          .test(
            "dob",
            "Nominee's Age should be more than 18 years",
            function (val) {
              return moment().diff(moment(val), "years") >= 18;
            }
          ),
        nominee_relation: yup.string().required("Relation is required"),
        nominee_name: yup.string().required("Nominee's Name is required"),
      }),
    }),
  });
  useEffect(() => {
    setValues({
      ...values,
      gender: proposalData.gender || "M",
      dob: proposalData.dob,
      is_married: proposalData.is_married || "N",
      occupation: proposalData.occupation,
    });
  }, [proposalData]);
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Stack
          sx={{
            mt: 2,
          }}
          spacing={2}
        >
          {proposalData?.owner_type === "IND" && (
            <>
              <Box>
                <Typography variant='subtitle1' component={"p"}>
                  Gender
                </Typography>
                <RadioGroup
                  name='gender'
                  sx={{
                    flexDirection: "row",
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.gender}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label='Male'
                    value={"M"}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label='Female'
                    value={"F"}
                  />
                </RadioGroup>
              </Box>
              <Box>
                <Typography variant='subtitle1' component={"p"}>
                  Married
                </Typography>
                <RadioGroup
                  name='is_married'
                  sx={{
                    flexDirection: "row",
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.is_married}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label='Yes'
                    value={"Y"}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label='No'
                    value={"N"}
                  />
                </RadioGroup>
              </Box>
              <DatePicker
                label='Date of Birth'
                size={"small"}
                onChange={(val) => {
                  setFieldValue("dob", moment(val).format("YYYY-MM-DD"));
                }}
                maxDate={moment().subtract(18, "years")}
                value={values.dob}
                name='dob'
                readOnly={proposalData?.ckyc}
                onBlur={handleBlur}
                error={touched.dob && Boolean(errors.dob)}
                helperText={touched.dob && errors.dob}
              />
            </>
          )}
          <Select
            label='Occupation'
            size={"small"}
            handleChange={(value) => {
              setFieldValue("occupation", value);
            }}
            selectedValue={values.occupation}
            options={occupation_list || []}
            name='occupation'
            error={touched.occupation && Boolean(errors.occupation)}
            helperText={touched.occupation && errors.occupation}
          />
          {is_pa_selected && (
            <>
              <StyledInput
                label="Nominee's Name"
                size={"small"}
                onChange={handleChange}
                name='nominee_name'
                onBlur={handleBlur}
                value={values.nominee_name}
                error={touched.nominee_name && Boolean(errors.nominee_name)}
                helperText={touched.nominee_name && errors.nominee_name}
              />
              <DatePicker
                label="Nominee's Date of Birth"
                size={"small"}
                onChange={(val) => {
                  setFieldValue(
                    "nominee_dob",
                    moment(val).format("YYYY-MM-DD")
                  );
                }}
                value={values.nominee_dob}
                name='nominee_dob'
                maxDate={moment().subtract(18, "years")}
                onBlur={handleBlur}
                error={touched.nominee_dob && Boolean(errors.nominee_dob)}
                helperText={touched.nominee_dob && errors.nominee_dob}
              />
              <Select
                label="Nominee's Relation"
                size={"small"}
                handleChange={(value) => {
                  setFieldValue("nominee_relation", value);
                }}
                selectedValue={values.nominee_relation}
                options={relations || []}
                name='nominee_relation'
                error={
                  touched.nominee_relation && Boolean(errors.nominee_relation)
                }
                helperText={touched.nominee_relation && errors.nominee_relation}
              />
            </>
          )}
          <StyledButton type='submit' variant='contained' size='medium'>
            Proceed to Communication
          </StyledButton>
        </Stack>
      </form>
    </>
  );
};
const SummaryComp: React.FC = () => {
  return <></>;
};
//Mui Gender Radio component
const PersonalDetails = () => {
  return (
    <>
      <FormCard
        title='Personal Details'
        index={1}
        EditComp={EditComp}
        SummaryComp={SummaryComp}
      />
    </>
  );
};

export default PersonalDetails;
