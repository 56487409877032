import React, { useEffect, useState } from "react";

function useSearch<Keys extends string | number>(
  searchKey: Keys,
  searchList: { [key in Keys]: any }[]
) {
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState(searchList);
  useEffect(() => {
    if (searchList.length) setSearchResults(searchList);
  }, [searchList]);
  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    if (!e.target.value) {
      setSearchResults(searchList);
      return;
    }
    setSearchResults(
      searchList.filter((item) =>
        item[searchKey]
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      )
    );
  };

  return {
    searchText,
    onSearch,
    searchResults,
  };
}

export default useSearch;
