import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showVehicleDetailsPopup: false,
  showVehicleExpiryDetailsPopup: false,
  activeVehicleDetails: 0,
  vehicleDetails: {
    brand: { id: "", title: "" },
    model: { id: "", title: "" },
    variant: { id: "", title: "" },
    year: "",
    rto: {
      name: "",
      number: "",
    },
  },
};

const landing = createSlice({
  name: "landing",
  initialState,
  reducers: {
    showVehicleDetails: (state) => {
      state.showVehicleDetailsPopup = true;
      state.showVehicleExpiryDetailsPopup = false;
    },
    showVehicleExpiryDetails: (state) => {
      state.showVehicleDetailsPopup = false;
      state.showVehicleExpiryDetailsPopup = true;
    },
    closePopups: (state) => {
      state.showVehicleDetailsPopup = false;
      state.showVehicleExpiryDetailsPopup = false;
    },
    setActiveVehicleDetails: (state, action) => {
      state.activeVehicleDetails = action.payload;
    },
    setBrand: (state, action) => {
      state.vehicleDetails.brand = action.payload;
    },
    setModel: (state, action) => {
      state.vehicleDetails.model = action.payload;
    },
    setVariant: (state, action) => {
      state.vehicleDetails.variant = action.payload;
    },
    setYear: (state, action) => {
      state.vehicleDetails.year = action.payload;
    },
    setRto: (state, action) => {
      state.vehicleDetails.rto = action.payload;
    },
  },
});
export const {
  showVehicleDetails,
  showVehicleExpiryDetails,
  closePopups,
  setActiveVehicleDetails,
  setBrand,
  setModel,
  setVariant,
  setYear,
  setRto,
} = landing.actions;
export default landing.reducer;
