import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import Modal from "../../../../../components/Modal/Modal";
import StyledInput from "../../../../../components/TextInput/TextInput";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import useUrlQuery from "../../../../../hooks/useUrlQuery";
import * as yup from "yup";
import { StyledButton } from "../../../../../components/Button/Button";
import { commaSeparatedPrice } from "../../../../../utils";
import {
  useFilterMutation,
  useGetEligibleProductsQuery,
} from "./../../../../../services/CarServices";
import { useDispatch } from "react-redux";
import { setSelectedIDV } from "../../../../../modules/quotes.slice";
const IDVModal = () => {
  const { getUrlQuery, removeUrlQuery, setUrlQuery } = useUrlQuery();
  const isOpen = getUrlQuery("filter") === "idv";
  const { updateFilter } = useFilterMutation();
  const { requestData } = useGetEligibleProductsQuery();
  const dispatch = useDispatch();
  const { idv_values, selected_idv } = useTypedSelector((state) => state.quote);
  const { handleChange, values, handleSubmit } = useFormik({
    initialValues: {
      idv: selected_idv.type,
      custom_value: 0,
    },
    onSubmit: (values) => {
      setUrlQuery("idv", values.idv, {
        replace: true,
      });
      removeUrlQuery("filter");
      updateFilter({
        ...requestData,
        IDV:
          values.idv === "Custom"
            ? values.custom_value
            : idv_values[values.idv],
      });
      dispatch(
        setSelectedIDV({
          type: values.idv,
          value:
            values.idv === "Custom"
              ? values.custom_value
              : idv_values[values.idv],
        })
      );
    },
    validationSchema: yup.object().shape({
      idv: yup.string().required("Please select IDV"),
      custom_value: yup.string().when("idv", {
        is: "custom",
        then: yup
          .string()
          .required("Please enter custom IDV")
          .min(
            idv_values.LIDV,
            `IDV should be greater than ${commaSeparatedPrice(idv_values.LIDV)}`
          )
          .max(
            idv_values.HIDV,
            `IDV should be less than ${commaSeparatedPrice(idv_values.HIDV)}`
          ),
      }),
    }),
  });
  const onClose = () => {
    removeUrlQuery("filter");
  };
  return (
    <>
      <Modal
        open={isOpen}
        maxWidth={"xs"}
        title='Insured Value (IDV)'
        onClose={onClose}
      >
        <form
          style={{
            padding: "16px",
          }}
          onSubmit={handleSubmit}
        >
          <RadioGroup name='idv' onChange={handleChange}>
            <FormControlLabel
              value={"LIDV"}
              control={<Radio />}
              label={`Lowest IDV (${commaSeparatedPrice(idv_values.LIDV)})`}
            />
            <FormControlLabel
              value={"RIDV"}
              control={<Radio />}
              label={`Recommended IDV (${commaSeparatedPrice(
                idv_values.RIDV
              )})`}
            />
            <FormControlLabel
              value={"HIDV"}
              control={<Radio />}
              label={`Highest IDV (${commaSeparatedPrice(idv_values.HIDV)})`}
            />
            <FormControlLabel
              value={"Custom"}
              control={<Radio />}
              label={`Custom Value`}
            />
          </RadioGroup>
          {values.idv === "Custom" && (
            <>
              <Box
                sx={{
                  position: "absolute",
                  bottom: 86,
                  right: "16px",
                }}
              >
                <TextField
                  label='Custom Value'
                  size='medium'
                  name='custom_value'
                  variant='standard'
                  onChange={handleChange}
                />
              </Box>
            </>
          )}
          <Grid container spacing={1} mt={1}>
            <Grid item xs={6}>
              <StyledButton
                variant='outlined'
                fullWidth
                type='button'
                onClick={onClose}
              >
                Cancel
              </StyledButton>
            </Grid>
            <Grid item xs={6}>
              <StyledButton variant='contained' fullWidth type='submit'>
                Apply
              </StyledButton>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </>
  );
};

export default IDVModal;
