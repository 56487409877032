import { TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { MomentInput, Moment } from "moment";
import { useState } from "react";

type Views = "year" | "month" | "day";

interface DatePickerProps {
  label: string;
  value: string | null;
  views?: Views[];
  onChange: (value: string | null) => void; // Adjusted the type to match the output format
  minDate?: Moment;
  maxDate?: Moment;
  openTo?: Views;
  name: string;
  onBlur?: (e: any) => void;
  inputFormat?: string;
  outputFormat?: string; // Added output format support
  size?: "small" | "medium";
  error?: boolean;
  helperText?: string | false;
  disableFuture?: boolean;
  readOnly?: boolean;
}

const DatePicker: React.FC<DatePickerProps> = ({
  label,
  value,
  onChange,
  outputFormat = "YYYY-MM-DD", // Default output format
  ...props
}) => {
  const [open, setOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DesktopDatePicker
        label={label}
        open={props?.readOnly ? false : open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={(date) => {
          // Format the date based on the output format or send null
          const formattedDate = date ? moment(date).format(outputFormat) : null;
          onChange(formattedDate);
        }}
        value={value ? moment(value, outputFormat) : null} // Parse the value based on the output format
        views={props.views || ["year", "month", "day"]}
        openTo={props.openTo || "year"}
        inputFormat={props.inputFormat || "DD/MM/YYYY"}
        disableFuture={props.disableFuture}
        minDate={props.minDate}
        maxDate={props.maxDate}
        renderInput={(params) => (
          <TextField
            fullWidth
            variant="outlined"
            onClick={() => setOpen(!open)}
            sx={{
              ...(props.size === "small" && {
                ".MuiOutlinedInput-root": {
                  height: "50px",
                },
                ".MuiInputLabel-root": {
                  top: "3px",
                },
                ".MuiInputLabel-shrink": {
                  top: "-1px",
                },
              }),
            }}
            size={props.size}
            helperText={props.helperText}
            onBlur={props.onBlur}
            name={props.name}
            {...params}
            inputProps={{
              ...params.inputProps,
              readOnly: props.readOnly,
            }}
            error={props.error}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
