import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useRef, useState } from "react";
import "animate.css";
import {
  Add,
  ArrowBack,
  ArrowForward,
  Delete,
  Mail,
  Phone,
} from "@mui/icons-material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { onInputPhone } from "../../utils";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useSubmitQRLeadsMutation } from "../../services/ShareServices";
import Lottie from "react-lottie-player";
import confetti from "./confetti.json";
const validateName = (name) => {
  if (!name) {
    return {
      name: "Name is required",
    };
  }
  if (name.length < 3) {
    return {
      name: "Name must be atleast 3 characters long",
    };
  } else return false;
};
const validateSelf = (data) => {
  let errors = {};
  let hasErrors = false;
  if (!data.gender) {
    hasErrors = true;
    errors = {
      ...errors,
      gender: "Gender is Required",
    };
  }
  if (!data.dob) {
    hasErrors = true;
    errors = {
      ...errors,
      dob: "DOB is Required",
    };
  }
  // age should be greater than 18 using moment
  if (data.dob && moment().diff(data.dob, "years") < 18) {
    hasErrors = true;
    errors = {
      ...errors,
      dob: "Age should be greater than 18",
    };
  }
  return hasErrors ? errors : false;
};

const validatePersonalInfo = (data) => {
  let errors = {};
  let hasErrors = false;
  if (!data.phone) {
    hasErrors = true;
    errors = {
      ...errors,
      phone: "Phone is Required",
    };
  }
  if (data.phone) {
    const phoneRegex = /^[6-9]\d{9}$/;
    if (!phoneRegex.test(data.phone)) {
      hasErrors = true;
      errors = {
        ...errors,
        phone: "Please enter a valid phone number",
      };
    }
  }

  if (!data.email) {
    hasErrors = true;
    errors = {
      ...errors,
      email: "Email is Required",
    };
  }
  if (data.email) {
    const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    if (!emailRegex.test(data.email)) {
      hasErrors = true;
      errors = {
        ...errors,
        email: "Please enter a valid mail",
      };
    }
  }
  if (!data.pincode) {
    hasErrors = true;
    errors = {
      ...errors,
      pincode: "Pincode is Required",
    };
  }
  if (data.pincode) {
    const pincodeRegex = /^[1-9][0-9]{5}$/;
    if (!pincodeRegex.test(data.pincode)) {
      hasErrors = true;
      errors = {
        ...errors,
        pincode: "Please enter a valid pincode",
      };
    }
  }
  if (!data.medicalName) {
    hasErrors = true;
    errors = {
      ...errors,
      medicalName: "Medical Store Name is Required",
    };
  }
  return hasErrors ? errors : false;
};
const LeadCapture = () => {
  const { submitQRLeadsAsync } = useSubmitQRLeadsMutation();
  const theme = useTheme();
  const nameRef = useRef();
  const selfRef = useRef();
  const ageRef = useRef();
  const spouseRef = useRef();
  const personalInfoRef = useRef();
  const thankyouRef = useRef();
  const [step, setStep] = useState(0);
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [forSpouse, setForSpouse] = useState();
  const [spouseDob, setSpouseDob] = useState();
  const [child, setChild] = useState([]);
  const [medicalName, setMedicalName] = useState("");
  const [selfGender, setSelfGender] = useState("");
  const addChild = () => {
    setChild((prev) => [...prev, { name: "", dob: "" }]);
  };
  const removeChild = (index) => {
    setChild((prev) => prev.filter((_, i) => i !== index));
  };
  const clearClassesFromAllRef = () => {
    nameRef.current.classList.remove(
      "animate__animated",
      "animate__slideOutLeft",
      "animate__slideInRight"
    );
    selfRef.current.classList.remove(
      "animate__animated",
      "animate__slideOutLeft",
      "animate__slideInRight"
    );
    ageRef.current.classList.remove(
      "animate__animated",
      "animate__slideOutLeft",
      "animate__slideInRight"
    );
    personalInfoRef.current.classList.remove(
      "animate__animated",
      "animate__slideOutLeft",
      "animate__slideInRight"
    );
    thankyouRef.current.classList.remove(
      "animate__animated",
      "animate__slideOutLeft",
      "animate__slideInRight"
    );
  };
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [pincode, setPincode] = useState("");
  const [errors, setErrors] = useState({});
  const { id } = useParams();
  const handleNext = async () => {
    clearClassesFromAllRef();
    if (step === 0) {
      const hasErrors = validateName(name);
      if (hasErrors) {
        setErrors(hasErrors);
        return;
      } else {
        setErrors({});
      }
      setStep(1);
      nameRef.current.style.display = "none";
      selfRef.current.style.display = "block";
      selfRef.current.classList.add(
        "animate__animated",
        "animate__slideInRight"
      );
    } else if (step === 1) {
      const hasErrors = validateSelf({
        dob,
        gender: selfGender,
      });
      if (hasErrors) {
        setErrors(hasErrors);
        return;
      } else {
        setErrors({});
      }
      setStep(2);
      selfRef.current.style.display = "none";
      ageRef.current.style.display = "block";
      ageRef.current.classList.add(
        "animate__animated",
        "animate__slideInRight"
      );
    } else if (step === 2) {
      setStep(3);
      ageRef.current.style.display = "none";
      personalInfoRef.current.style.display = "block";
      personalInfoRef.current.classList.add(
        "animate__animated",
        "animate__slideInRight"
      );
    } else if (step === 3) {
      const hasErrors = validatePersonalInfo({
        phone,
        email,
        pincode,
        medicalName,
      });
      if (hasErrors) {
        setErrors(hasErrors);
        return;
      } else {
        setErrors({});
      }
      const finalData = {
        FNAME: name.split(" ")[0],
        LNAME: name.split(" ")[1] || "",
        DOB: dob,
        GENDER: selfGender,
        SPOUSE_DOB: spouseDob,
        SPOUSE_GENDER: forSpouse
          ? selfGender === "male"
            ? "female"
            : "male"
          : "",
        CHILD: child,
        MOBILE: phone,
        EMAIL: email,
        PINCODE: pincode,
        SALES_PERSON_ID: id,
        LOB: "HEALTH",
        MEDICAL_STORE_NAME: medicalName,
      };
      await submitQRLeadsAsync(finalData);
      setStep(4);
      personalInfoRef.current.style.display = "none";
      thankyouRef.current.style.display = "block";
      thankyouRef.current.classList.add(
        "animate__animated",
        "animate__slideInRight"
      );
    }
  };

  const handleBack = () => {
    clearClassesFromAllRef();
    if (step === 1) {
      setStep(0);
      nameRef.current.style.display = "block";
      nameRef.current.classList.add(
        "animate__animated",
        "animate__slideInLeft"
      );
      selfRef.current.style.display = "none";
    } else if (step === 2) {
      setStep(1);
      selfRef.current.style.display = "block";
      selfRef.current.classList.add(
        "animate__animated",
        "animate__slideInLeft"
      );
      ageRef.current.style.display = "none";
    } else if (step === 3) {
      setStep(2);
      ageRef.current.style.display = "block";
      ageRef.current.classList.add("animate__animated", "animate__slideInLeft");
      personalInfoRef.current.style.display = "none";
    }
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        background: theme.palette.primary.main,
        p: 1,
      }}
    >
      <Paper
        sx={{
          minHeight: "400px",
        }}
      >
        <Typography variant="h5" align="center" color="primary.main">
          Cashless Mediclaim Policy
        </Typography>
        <Typography variant="h6" align="center" color="text.secondary">
          Get a Free Quote
        </Typography>
        <Box
          px={1}
          mt={2}
          sx={{
            overflow: "hidden",
            "& > div": {
              width: "100%",
              margin: "0 auto",
            },
          }}
        >
          <div ref={nameRef}>
            <Grid container spacing={2}>
              <SectionHeading
                handleBack={handleBack}
                title={`
                Hi, I am your personal assistant. Please tell me your name.
                `}
              />
              <Grid item xs={12}>
                <Box px={2} mb={2}>
                  <TextField
                    sx={{
                      "& legend": {
                        display: "none",
                      },
                    }}
                    placeholder="Your Full Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    error={!!errors.name}
                    helperText={errors.name}
                    fullWidth
                  ></TextField>
                </Box>
              </Grid>
              <SectionFooter handleNext={handleNext} />
            </Grid>
          </div>
          <div
            ref={selfRef}
            style={{
              display: "none",
            }}
          >
            <Grid container spacing={2}>
              <SectionHeading
                handleBack={handleBack}
                title={`Hi ${name}, Please provide your Gender & Date of Birth.
                `}
              />
              <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                <div className="radio-container">
                  <input
                    type="radio"
                    id="Self_Male"
                    name="gender"
                    value={"male"}
                    checked={selfGender === "male"}
                    onChange={(e) => {
                      setSelfGender(e.target.value);
                    }}
                  />
                  <label htmlFor="Self_Male">Male</label>
                  <input
                    type="radio"
                    id="Self_Female"
                    name="SelfGender"
                    value={"female"}
                    checked={selfGender === "female"}
                    onChange={(e) => {
                      setSelfGender(e.target.value);
                    }}
                  />
                  <label htmlFor="Self_Female">Female</label>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"center"}
                mb={2}
              >
                {" "}
                <TextField
                  sx={{
                    "& legend": {
                      display: "none",
                    },
                  }}
                  type="date"
                  placeholder="Your Date of Birth"
                  fullWidth
                  value={dob}
                  error={!!errors.dob}
                  helperText={errors.dob}
                  inputProps={{
                    min: moment().subtract(100, "years").format("YYYY-MM-DD"),
                    max: moment().subtract(18, "years").format("YYYY-MM-DD"),
                  }}
                  onChange={(e) => setDob(e.target.value)}
                />
              </Grid>
              <SectionFooter handleNext={handleNext} />
            </Grid>
          </div>
          <div
            ref={ageRef}
            style={{
              display: "none",
            }}
          >
            <Grid container spacing={2}>
              <SectionHeading
                title={`Thanks ${name}, Do you need a cover for your spouse or children?`}
                handleBack={handleBack}
              />
              <Grid display={"flex"} justifyContent={"center"} item xs={12}>
                <div className="radio-container">
                  <input
                    type="radio"
                    id="Yes"
                    name="forSpouse"
                    value={"Y"}
                    checked={forSpouse === "Y"}
                    onChange={(e) => {
                      setForSpouse(e.target.value);
                      spouseRef.current.style.display = "block";
                      spouseRef.current.classList.add(
                        "animate__animated",
                        "animate__slideInRight"
                      );
                    }}
                  />
                  <label htmlFor="Yes">Yes</label>
                  <input
                    type="radio"
                    id="No"
                    name="forSpouse"
                    value={"N"}
                    checked={forSpouse === "N"}
                    onChange={(e) => {
                      setForSpouse(e.target.value);
                      spouseRef.current.style.display = "none";
                      spouseRef.current.classList.remove(
                        "animate__animated",
                        "animate__slideInRight"
                      );
                      handleNext();
                    }}
                  />
                  <label htmlFor="No">No</label>
                </div>
              </Grid>
            </Grid>
            <div
              ref={spouseRef}
              style={{
                display: "none",
                width: "100%",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack spacing={2} width={"100%"}>
                    <Typography
                      mt={2}
                      variant="body1"
                      color="text.secondary"
                      align="center"
                    >
                      Please provide your spouse's DOB.
                    </Typography>
                    <TextField
                      type="date"
                      sx={{
                        "& legend": {
                          display: "none",
                        },
                      }}
                      placeholder="Your Spouse Date of Birth"
                      inputProps={{
                        min: moment()
                          .subtract(100, "years")
                          .format("YYYY-MM-DD"),
                        max: moment()
                          .subtract(18, "years")
                          .format("YYYY-MM-DD"),
                      }}
                      value={spouseDob}
                      fullWidth
                      onChange={(e) => setSpouseDob(e.target.value)}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Box px={2} mb={2} display={"flex"} justifyContent={"right"}>
                    <Button
                      variant="contained"
                      startIcon={<Add />}
                      onClick={addChild}
                    >
                      Add Children
                    </Button>
                  </Box>
                </Grid>
                {child.map((item, index) => {
                  return (
                    <>
                      <Grid item xs={12}>
                        <Stack
                          spacing={2}
                          width={"100%"}
                          justifyContent={"center"}
                          aignItems={"center"}
                          position={"relative"}
                        >
                          <IconButton
                            sx={{
                              position: "absolute",
                              top: "8px",
                              right: "-12px",
                            }}
                            onClick={() => removeChild(index)}
                          >
                            <Delete />
                          </IconButton>

                          <Typography
                            variant="body1"
                            color="text.secondary"
                            align="center"
                          >
                            Please provide your child {index + 1} DOB & Gender.
                          </Typography>
                          <div className="radio-container center">
                            <input
                              type="radio"
                              id={`Male${index}`}
                              name={`gender${index}`}
                              value={"male"}
                              checked={item.gender === "male"}
                              onChange={(e) => {
                                const temp = [...child];
                                setChild(
                                  temp.map((item, i) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        gender: e.target.value,
                                      };
                                    }
                                    return item;
                                  })
                                );
                              }}
                            />
                            <label htmlFor={`Male${index}`}>Male</label>
                            <input
                              type="radio"
                              id={`Female${index}`}
                              name={`gender${index}`}
                              value={"female"}
                              checked={item.gender === "female"}
                              onChange={(e) => {
                                const temp = [...child];
                                setChild(
                                  temp.map((item, i) => {
                                    if (i === index) {
                                      return {
                                        ...item,
                                        gender: e.target.value,
                                      };
                                    }
                                    return item;
                                  })
                                );
                              }}
                            />
                            <label htmlFor={`Female${index}`}>Female</label>
                          </div>
                          <TextField
                            type="date"
                            sx={{
                              "& legend": {
                                display: "none",
                              },
                            }}
                            placeholder="Your Child Date of Birth"
                            fullWidth
                            value={item.dob}
                            inputProps={{
                              min: moment()
                                .subtract(25, "years")
                                .format("YYYY-MM-DD"),
                              max: moment()
                                .subtract(1, "years")
                                .format("YYYY-MM-DD"),
                            }}
                            onChange={(e) => {
                              const temp = [...child];
                              setChild(
                                temp.map((item, i) => {
                                  if (i === index) {
                                    return {
                                      ...item,
                                      dob: e.target.value,
                                    };
                                  }
                                  return item;
                                })
                              );
                            }}
                          />
                        </Stack>
                      </Grid>
                    </>
                  );
                })}
                <SectionFooter handleNext={handleNext} />
              </Grid>
            </div>
          </div>
          <div
            ref={personalInfoRef}
            style={{
              display: "none",
            }}
          >
            <Grid container spacing={2}>
              <SectionHeading
                title={`Thanks ${name}, Please provide your personal information.`}
                handleBack={handleBack}
              />
              <Grid item xs={12}>
                <TextField
                  sx={{
                    "& legend": {
                      display: "none",
                    },
                  }}
                  type="tel"
                  placeholder="Your Phone Number"
                  fullWidth
                  onInput={onInputPhone}
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  error={!!errors.phone}
                  helperText={errors.phone}
                  inputProps={{
                    maxLength: 10,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Phone />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="email"
                  sx={{
                    "& legend": {
                      display: "none",
                    },
                  }}
                  placeholder="Your Email"
                  fullWidth
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  error={!!errors.email}
                  helperText={errors.email}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Mail />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="tel"
                  sx={{
                    "& legend": {
                      display: "none",
                    },
                  }}
                  placeholder="Your Pincode"
                  onInput={onInputPhone}
                  inputProps={{
                    maxLength: 6,
                  }}
                  value={pincode}
                  onChange={(e) => {
                    setPincode(e.target.value);
                  }}
                  error={!!errors.pincode}
                  helperText={errors.pincode}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOnIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" color="text.secondary">
                  Please provide the name of the medical store
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{
                    "& legend": {
                      display: "none",
                    },
                  }}
                  placeholder="Medical Store Name"
                  value={medicalName}
                  onChange={(e) => setMedicalName(e.target.value)}
                  fullWidth
                  error={!!errors.medicalName}
                  helperText={errors.medicalName}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color="text.secondary">
                  By clicking on submit, you agree to our{" "}
                  <a href="https://www.vkover.com/terms-and-conditions/">
                    Terms of Use
                  </a>{" "}
                  and{" "}
                  <a href="https://www.vkover.com/privacy-policy/">
                    Privacy Policy
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box px={2} mb={2} display={"flex"} justifyContent={"right"}>
                  <Button variant="contained" onClick={handleNext}>
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              display: "none",
            }}
            ref={thankyouRef}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{
                  position: "relative",
                }}
              >
                <Box position={"absolute"}>
                  <Lottie loop={2} animationData={confetti} play={step === 4} />
                </Box>
                <Typography
                  variant="h6"
                  align="center"
                  mt={4}
                  color="primary.main"
                >
                  Thank you for submitting your details. Our representative will
                  contact you shortly.
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Paper>
    </Box>
  );
};

export default LeadCapture;

const SectionHeading = ({ title, handleBack }) => {
  return (
    <>
      <Grid item xs={2}>
        <IconButton onClick={handleBack}>
          <ArrowBack />
        </IconButton>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="body1" color="text.secondary">
          {title}
        </Typography>
      </Grid>
    </>
  );
};

const SectionFooter = ({ handleNext }) => {
  return (
    <Grid item xs={12}>
      <Box px={2} mb={2} display={"flex"} justifyContent={"right"}>
        <Button
          variant="contained"
          endIcon={<ArrowForward />}
          onClick={handleNext}
        >
          Next
        </Button>
      </Box>
    </Grid>
  );
};
