import { Box, Divider, makeStyles, Paper, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import TextButton from "../../../components/TextButton/TextButton";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { setActiveForm } from "../../../modules/proposal.slice";
const paperStyle = {
  background: "rgb(255, 255, 255)",
  boxShadow: "rgb(0 0 0 / 6%) 0px 4px 12px",
  maxWidth: "608px",
  mx: "auto",
  p: 2,
  mb: 2,
  "@media (max-width: 991px)": {
    maxWidth: "608px",
    width: "100%",
  },
};
const SectionTitle: React.FC = ({ children }) => {
  return (
    <>
      <Typography
        mb={1}
        sx={{
          fontWeight: 700,
          color: "var(--tertiary-color)",
          fontSize: "20px",
        }}
        variant='h2'
        component='h2'
      >
        {children}
      </Typography>
    </>
  );
};
interface FormCardProps {
  title: string;
  EditComp: React.FC;
  index: 0 | 1 | 2 | 3 | 4;
  SummaryComp: React.FC;
}
const FormCard: React.FC<FormCardProps> = ({
  title,
  EditComp,
  index,
  SummaryComp,
}) => {
  const { activeForm } = useTypedSelector((state) => state.proposal);
  const dispatch = useDispatch();
  if (activeForm < index)
    return (
      <Paper sx={paperStyle}>
        <SectionTitle>{index + 1 + ". " + title}</SectionTitle>
        {/* <EditComp /> */}
      </Paper>
    );
  else if (activeForm === index) {
    return (
      <Paper sx={paperStyle}>
        <SectionTitle>{index + 1 + ". " + title}</SectionTitle>
        <Divider />
        <EditComp />
      </Paper>
    );
  } else if (activeForm > index) {
    return (
      <>
        <Paper sx={paperStyle}>
          <SectionTitle>{index + 1 + ". " + title}</SectionTitle>
          <Box sx={{ float: "right", mt: "-28px" }}>
            <TextButton
              color='primary'
              onClick={() => {
                dispatch(setActiveForm(index));
              }}
            >
              Edit
            </TextButton>
          </Box>
          <Divider />
          <SummaryComp />
        </Paper>
      </>
    );
  } else return <></>;
};
export default FormCard;
