import { Grid, Typography } from "@mui/material";

const SectionDetail: React.FC<{
  main?: boolean;
  title: string;
  value: string | number | undefined;
}> = ({ title, value, main }) => {
  return (
    <>
      <Grid container mb={1} alignItems='center'>
        <Grid item xs={8}>
          {main ? (
            <Typography
              sx={{
                fontSize: "16px",
                color: "var(--tertiary-color)",
                fontWeight: 700,
              }}
            >
              {title}
            </Typography>
          ) : (
            <Typography
              sx={{
                fontSize: "14px",
                color: "var(--dark-grey-1)",
              }}
            >
              {title} :
            </Typography>
          )}
        </Grid>
        <Grid item xs={4}>
          {main ? (
            <Typography
              sx={{
                fontSize: "20px",
                color: "var(--secondary-color)",
                fontWeight: 700,
              }}
              align='right'
            >
              {value}
            </Typography>
          ) : (
            <Typography align='right' fontSize={14} fontWeight={600}>
              {value}
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default SectionDetail;
