import { createSlice } from "@reduxjs/toolkit";
import { PremiumInterface, ProductInterface } from "../services/QuoteServices";
export type IDV_RANGE = "LIDV" | "RIDV" | "HIDV";
type ADDONS = "NILL_DEPT" | "PA_COVER";
const initialState = {
  showEditExistingPolicyDetails: false,
  addOns: [] as ADDONS[],
  selected_plantype: "ALL",
  quote_end_points: [] as string[],
  idv_values: {
    LIDV: Infinity,
    HIDV: -Infinity,
  } as { [key in IDV_RANGE]: number },
  selected_idv: {
    type: "" as IDV_RANGE | "Custom",
    value: 0,
  },
  see_details: {
    quoteData: {} as {
      product_data: ProductInterface;
      premium_data: PremiumInterface;
      selected_addons: {
        name: string;
        slug: ADDONS;
        premium: string | undefined;
      }[];
    },
    isOpen: false,
  },
  isPADisclaimerAccepted: false,
};

const quote = createSlice({
  name: "quote",
  initialState,
  reducers: {
    editExistingPolicyDetails: (state) => {
      state.showEditExistingPolicyDetails = true;
    },
    closeExistingPolicyDetailsPopup: (state) => {
      state.showEditExistingPolicyDetails = false;
    },
    setAddOns: (state, action) => {
      if (!state.addOns.includes(action.payload))
        state.addOns.push(action.payload);
    },
    removeAddons: (state, action) => {
      state.addOns = state.addOns.filter((addOn) => addOn !== action.payload);
    },
    setQuoteEndPoints: (state, action) => {
      state.quote_end_points = action.payload;
    },
    setIDVValues: (state, action) => {
      state.idv_values.HIDV = Math.max(
        action.payload.IDV_MAX,
        state.idv_values.HIDV
      );
      state.idv_values.LIDV = Math.min(
        action.payload.IDV_MIN,
        state.idv_values.LIDV
      );
      state.idv_values.RIDV = Math.floor(
        (state.idv_values.HIDV + state.idv_values.LIDV) / 2
      );
    },
    setSelectedIDV: (state, action) => {
      state.selected_idv = action.payload;
    },
    openSeeDetails: (state, action) => {
      state.see_details.quoteData = action.payload;
      state.see_details.isOpen = true;
    },
    closeSeeDetails: (state) => {
      state.see_details.isOpen = false;
    },
    setSelectedPlanType: (state, action) => {
      state.selected_plantype = action.payload;
    },

    setPADisclaimerAccepted: (state, { payload }) => {
      state.isPADisclaimerAccepted = payload;
    },
    // openIDVModal: (state) => {
    //   state.isIDVModalOpen = true;
    //   state.isPlanTypeModalOpen = false;
    // },
    // closeIDVModal: (state) => {
    //   state.isIDVModalOpen = false;
    // },
    // openPlanTypeModal: (state) => {
    //   state.isPlanTypeModalOpen = true;
    //   state.isIDVModalOpen = false;
    // },
    // closePlanTypeModal: (state) => {
    //   state.isPlanTypeModalOpen = false;
    // },
  },
});
export const {
  editExistingPolicyDetails,
  closeExistingPolicyDetailsPopup,
  setAddOns,
  removeAddons,
  setQuoteEndPoints,
  setIDVValues,
  setSelectedIDV,
  openSeeDetails,
  closeSeeDetails,
  setSelectedPlanType,
  setPADisclaimerAccepted,
} = quote.actions;
export default quote.reducer;
