import React from "react";
import TextButton from "../../../../components/TextButton/TextButton";
import styled from "styled-components";

interface DetailsCardProps {
  title: string;
  isVehicleDetails?: boolean;
  details: string[];
  onEdit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const DetailsCard: React.FC<DetailsCardProps> = ({
  title,
  isVehicleDetails,
  details,
  onEdit,
}) => {
  return (
    <>
      <Center isVehicleDetails={isVehicleDetails}>
        <DetailsWrapper>
          <div>
            <Title isVehicleDetails={isVehicleDetails}>{title}</Title>
            <DetailsList>
              {details.map((item) => (
                <li>{item}</li>
              ))}
            </DetailsList>
          </div>
          <EditButton color={"tertiary"} onClick={onEdit}>
            Edit
          </EditButton>
        </DetailsWrapper>
      </Center>
    </>
  );
};
const DetailsWrapper = styled.div<{ isVehicleDetails?: boolean }>`
  display: flex;
  align-items: center;

  gap: 60px;

  padding: 20px 0;
`;
const Center = styled.div<{ isVehicleDetails?: boolean }>`
  display: flex;
  justify-content: center;
  width: ${(props) =>
    props.isVehicleDetails ? "calc(40% - 2px)" : "calc(60% - 2px)"};
  transition: box-shadow 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 4px 12px rgba(0, 110, 144, 0.16);
  }
  position: relative;

  &:not(:last-child) {
    &::after {
      content: "";
      position: absolute;
      height: 70%;
      top: 50%;
      transform: translateY(-50%);
      right: -2px;
      width: 1px;

      background: var(--light-grey-2);
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    &:hover {
      box-shadow: none;
    }
    &:not(:last-child) {
      &::after {
        display: none;
      }
    }
  }
`;
const Title = styled.div<{ isVehicleDetails?: boolean }>`
  font-weight: 700;
  font-size: 18px;
  color: ${(props) =>
    props.isVehicleDetails ? "var(--primary-color)" : "inherit"};
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;
const DetailsList = styled.ul`
  list-style: none;
  margin: 6px 0 0;
  padding-left: 0;
  & li {
    display: inline-block;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
  @media (max-width: 768px) {
    margin: 0;
    & li {
      font-size: 12px;
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
`;
const EditButton = styled(TextButton)`
  font-size: 18px;
  right: 20px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;
export default DetailsCard;
