import React from "react";
import StyledSelect from "../../../../../components/Select/Select";
import { Question } from "../VehicleDetails.style";
import styled from "styled-components";
import { StyledButton } from "../../../../../components/Button/Button";
import { useDispatch } from "react-redux";
import {
  setRto,
  setYear,
  showVehicleExpiryDetails,
} from "../../../../../modules";
import useUrlQuery from "../../../../../hooks/useUrlQuery";
import { useGetRTOQuery } from "../../../../../services/CarServices";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import MobileSectionLabel from "./MobileSectionLabel";
import { useMediaQuery } from "@mui/material";
const getLastFifteenYears = () => {
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i > currentYear - 15; i--) {
    years.push({ OPTION_KEY: i.toString(), OPTION_VAL: i.toString() });
  }
  return years;
};
const Registration = () => {
  const dispatch = useDispatch();
  const { getUrlQuery, setUrlQuery } = useUrlQuery();
  const { rtoList, isRTOLoading } = useGetRTOQuery();
  // This will open the next popup
  const { rto, year } = useTypedSelector(
    (state) => state.landing.vehicleDetails
  );
  const onClick = () => {
    if (getUrlQuery("flow") !== "new") {
      setUrlQuery("stage", "LEAD");
    }
  };
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <>
      {isMobile && (
        <MobileSectionLabel
          label='Enter your bike registration year?'
          onClick={() => {}}
        />
      )}
      <Question>
        Where is your <span>bike</span> registered?
      </Question>
      <SelectWrapper>
        <StyledSelect
          label='Registration Place'
          options={rtoList}
          handleChange={(val) => {
            dispatch(
              setRto({
                number: val,
                name: rtoList.find((item) => item.OPTION_KEY === val)
                  ?.OPTION_VAL,
              })
            );
          }}
          selectedValue={rto.number}
        ></StyledSelect>
      </SelectWrapper>
      <Question>
        When is your <span>bike</span> registered?
      </Question>
      <SelectWrapper>
        <StyledSelect
          label='Registration Year'
          options={getLastFifteenYears()}
          selectedValue={year}
          handleChange={(val) => {
            dispatch(setYear(val));
          }}
        ></StyledSelect>
      </SelectWrapper>
      <div style={{ width: "300px", margin: "auto" }}>
        <StyledButton variant='contained' enlarged fullWidth onClick={onClick}>
          Save and Continue
        </StyledButton>
      </div>
    </>
  );
};

export default Registration;

const SelectWrapper = styled.div`
  width: 60%;
  margin: 0 auto 30px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
