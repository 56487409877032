import { ArrowBack } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
const MobileSectionLabel: React.FC<{
  label: string;
  onClick: () => void;
}> = (props) => {
  return (
    <Box display='flex' alignItems={"center"}>
      <IconButton onClick={props.onClick}>
        <ArrowBack />
      </IconButton>
      <Typography
        variant='body2'
        fontSize='16px'
        sx={{
          flexGrow: 1,
          textAlign: "center",
          marginLeft: "-20px",
        }}
      >
        {props.label}
      </Typography>
    </Box>
  );
};

export default MobileSectionLabel;
