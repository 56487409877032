import { useFormik } from "formik";
import { useEffect } from "react";
import FormCard from "../FormCard";
import * as yup from "yup";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import StyledInput from "../../../../components/TextInput/TextInput";
import { uppercase } from "../../../../utils";
import Select from "../../../../components/Select/Select";
import {
  useGetProposalDetailsQuery,
  useProposalFinalSubmitMutation,
  useUpdateProposalMutation,
} from "../../../../services/ProposalServices";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { StyledButton } from "../../../../components/Button/Button";
import { InputWrapper } from "../../../Landing/components/VehicleExpiry/VehicleExpiry.style";
import DatePicker from "../../../../components/DatePicker/DatePicker";
import moment from "moment";
import RadioButton from "../../../../components/Radio Button/RadioButton";

const EditComp: React.FC = () => {
  const { product_data, quote_data } = useGetProposalDetailsQuery();  // Call the hook correctly
  const { proposalData } = useTypedSelector((state) => state.proposal);

  const {
    updateProposal,
    isSubmitted,
    isSubmitting: isProposalSaving,
  } = useUpdateProposalMutation({ isFormData: true });
  const { submitProposal, isSubmitting } = useProposalFinalSubmitMutation();

  useEffect(() => {
    if (isSubmitted && !isProposalSaving) {
      submitProposal();
    }
  }, [isSubmitted, isProposalSaving]);

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    touched,
    errors,
    setValues,
    handleBlur,
  } = useFormik({
    initialValues: {
      is_ckyc_no: "N",
      ckyc_no: "",
      method_of_verification: "",
      pan_no: "",
      address_proof_doc: null,
      address_proof: "",
      identity_proof_doc: null,
      identity_proof: "",
      photo_doc: null,
      full_name: "",
      ckyc_pan_no: "",
      pan_dob: "",
      aadhaar_number: "",
      aadhaar_dob: "",
      ckyc_dob: "",
      ckyc_gender: "",
      dl_number: "",
      dl_dob: "",
      file_number:"",
      passport_dob: "",
    },
    onSubmit: (values) => {
      updateProposal({
        ...values,
      });
    },
    validationSchema: yup.object().shape({
      is_ckyc_no: yup.mixed().required(),
      ckyc_no: yup
        .string()
        .nullable()
        .when("is_ckyc_no", {
          is: "Y",
          then: yup
            .string()
            .required("This field is required")
            .matches(/^[0-9]{14}$/, "Please enter a valid CKYC number"), // Adjusted regex for CKYC
        }),
      method_of_verification: yup
        .string()
        .nullable()
        .when("is_ckyc_no", {
          is: "N",
          then: yup.string().required("This field is required"),
        }),
      pan_no: yup
        .string()
        .nullable()
        .when(["is_ckyc_no", "method_of_verification"], {
          is: (is_ckyc_no: string, method_of_verification: string) =>
            is_ckyc_no === "N" && method_of_verification === "pan",
          then: yup
            .string()
            .required("This field is required")
            .matches(
              /^[A-Z]{3}[P]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/,
              "Please enter a valid PAN number."
            ),
        }),
      address_proof: yup.string().nullable(),
      identity_proof: yup.string().nullable(),
      address_proof_doc: yup.mixed().nullable(),
      identity_proof_doc: yup.mixed().nullable(),
      photo_doc: yup.mixed().nullable(),
    }),
  });

  useEffect(() => {
    setValues({
      ...values,
      pan_no: proposalData?.pan_no,
    });
  }, [proposalData?.pan_no]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Stack
          sx={{
            mt: 2,
          }}
          spacing={2}
        >
          <Box>
            <Typography variant='subtitle1' component={"p"}>
              Do you have CKYC no.?
            </Typography>
            <RadioGroup
              name='is_ckyc_no'
              sx={{
                flexDirection: "row",
              }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.is_ckyc_no}
            >
              <FormControlLabel control={<Radio />} label='Yes' value={"Y"} />
              <FormControlLabel control={<Radio />} label='No' value={"N"} />
            </RadioGroup>
          </Box>

          {(values?.is_ckyc_no === "Y" && product_data?.INSURANCE_SLUG === "icici_lombard_general") && (
            <>
              <StyledInput
                label='CKYC Number'
                size={"small"}
                onChange={handleChange}
                name='ckyc_no'
                value={values.ckyc_no}
                onBlur={handleBlur}
                error={touched.ckyc_no && Boolean(errors.ckyc_no)}
                helperText={touched.ckyc_no && errors.ckyc_no}
              />
                <InputWrapper>
                  <DatePicker
                    label="CKYC DOB"
                    size={"medium"}
                    onChange={(val) => {
                      setFieldValue("ckyc_dob", moment(val).format("YYYY-MM-DD"));
                    } }
                    value={values.ckyc_dob}
                    maxDate={moment().subtract(18, "years")}
                    onBlur={handleBlur}
                    error={touched.ckyc_dob && Boolean(errors.ckyc_dob)}
                    helperText={touched.ckyc_dob && errors.ckyc_dob}
                     name="ckyc_dob"   />
                </InputWrapper>
            </>
          )}

          {values?.is_ckyc_no === "N" &&
            product_data?.INSURANCE_SLUG === "icici_lombard_general" && (
              <>
                <Select
                  label='Method of verification'
                  size={"small"}
                  handleChange={(value) => {
                    setFieldValue("method_of_verification", value);
                  }}
                  selectedValue={values.method_of_verification}
                  options={verification_methods_icici || []} // Use correct ICICI verification options
                  name='method_of_verification'
                  onBlur={handleBlur}
                  error={
                    touched.method_of_verification &&
                    Boolean(errors.method_of_verification)
                  }
                  helperText={
                    touched.method_of_verification &&
                    errors.method_of_verification
                  }
                />
    {values.method_of_verification === "pancard" && (
      <>
        <InputWrapper>
          <StyledInput
            size="medium"
            label="Pan Number"
            error={touched.ckyc_pan_no && Boolean(errors.ckyc_pan_no)}
            helperText={touched.ckyc_pan_no && errors.ckyc_pan_no}    
            onChange={(e) => {
              uppercase(e);
              handleChange(e);
            }}  
            name='ckyc_pan_no'
            value={values.ckyc_pan_no}
            onBlur={handleBlur}
            maxLength={10}
          />
        </InputWrapper>
        <InputWrapper>
        <DatePicker
                label="Pancard Date of Birth"
                size={"small"}
                onChange={(val) => {
                  setFieldValue(
                    "pan_dob",
                    moment(val).format("YYYY-MM-DD")
                  );
                }}
                value={values.pan_dob}
                name='pan_dob'
                maxDate={moment().subtract(18, "years")}
                onBlur={handleBlur}
                error={touched.pan_dob && Boolean(errors.pan_dob)}
                helperText={touched.pan_dob
                   && errors.pan_dob}
              />
        </InputWrapper>
      </>
    )}

    {values.method_of_verification === "aadharcard" && (
      <>
        <InputWrapper>
          <StyledInput
            size="medium"
            label="Aadhar Number"
            error={touched.aadhaar_number && Boolean(errors.aadhaar_number)}
            helperText={touched.aadhaar_number && errors.aadhaar_number}     
            onChange={(e) => {
            handleChange(e);
            } }
            maxLength={12}
            value={values?.aadhaar_number}
            name="aadhaar_number"
          />
        </InputWrapper>
        <InputWrapper>
          <StyledInput
            size="medium"
            label="Aadhar Name"
            error={touched.full_name && Boolean(errors.full_name)}
            helperText={touched.full_name && errors.full_name}     
            onChange={(e) => {
            handleChange(e);
            } }
            maxLength={56}
            value={values?.full_name}
            name="full_name"
          />
        </InputWrapper>
        <InputWrapper>
                                             <DatePicker
                label="Date of Birth"
                size={"small"}
                onChange={(val) => {
                  setFieldValue(
                    "aadhaar_dob",
                    moment(val).format("YYYY-MM-DD")
                  );
                }}
                value={values.aadhaar_dob}
                name='aadhaar_dob'
                maxDate={moment().subtract(18, "years")}
                onBlur={handleBlur}
                error={touched.aadhaar_dob && Boolean(errors.aadhaar_dob)}
                helperText={touched.aadhaar_dob && errors.aadhaar_dob}
              />
        </InputWrapper>
        <InputWrapper>
        <Select
                        label="Gender"
                        name={`gender`}
                        options={genderOptions}
                      />
        </InputWrapper>
      </>
    )}

    {values.method_of_verification === "drivingdetails" && (
      <>
        <InputWrapper>
          <StyledInput
            size="medium"
            label="Driving License Number"
            error={touched.dl_number && Boolean(errors.dl_number)}
            helperText={touched.dl_number && errors.dl_number}     
            onChange={(e) => {
            handleChange(e);
            } }
            maxLength={16}
            value={values?.dl_number}
            name="dl_number"
          />
        </InputWrapper>
        <InputWrapper>
          {/* <DatePicker
                      label="Driving License Date of Birth"
                      size="medium"
                      maxDate={moment().subtract(18, "years")}
                      minDate={moment().subtract(60, "years")}
                      error={touched.dl_dob && Boolean(errors.dl_dob)}
                      helperText={touched.dl_dob && errors.dl_dob}
                      onChange={(e) => {
                        handleChange(e);
                      } }
                      readOnly={proposalData?.ckyc}
                      outputFormat="YYYY-MM-DD"
                      value={values?.dl_dob}
                       name={"dl_dob"}          /> */}
                                     <DatePicker
                label="Driving License Date of Birth"
                size={"small"}
                onChange={(val) => {
                  setFieldValue(
                    "dl_dob",
                    moment(val).format("YYYY-MM-DD")
                  );
                }}
                value={values.dl_dob}
                name='dl_dob'
                maxDate={moment().subtract(18, "years")}
                onBlur={handleBlur}
                error={touched.dl_dob && Boolean(errors.dl_dob)}
                helperText={touched.dl_dob && errors.dl_dob}
              />
        </InputWrapper>
      </>
    )}

    {values.method_of_verification === "passportdetails" && (
      <>
        <InputWrapper>
          {/* <StyledInput
            size="medium"
            label="Passport Number"
            error={touched.file_number && Boolean(errors.file_number)}
            helperText={touched.file_number && errors.file_number}     
            onChange={(e) => {
            handleChange(e);
            } }
            maxLength={12}
            value={values?.file_number}
            name="file_number"
          /> */}
                        <StyledInput
                label="Passport Number"
                size={"medium"}
                onChange={handleChange}
                name='file_number'
                onBlur={handleBlur}
                // maxLength={12}
                value={values.file_number}
                error={touched.file_number && Boolean(errors.file_number)}
                helperText={touched.file_number && errors.file_number}
              />
        </InputWrapper>
        <InputWrapper>
          <DatePicker
                      label="Passport Date of Birth"
                      size="medium"
                      maxDate={moment().subtract(18, "years")}
                      minDate={moment().subtract(60, "years")}
                      error={touched.passport_dob && Boolean(errors.passport_dob)}
                      helperText={touched.passport_dob && errors.passport_dob}
                      onChange={(e) => {
                        handleChange(e);
                      } }
                      readOnly={proposalData?.ckyc}
                      outputFormat="YYYY-MM-DD"
                      value={values?.passport_dob} name={"passport_dob"}          />
                                                          <DatePicker
                label="Passport License Date of Birth"
                size={"medium"}
                onChange={(val) => {
                  setFieldValue(
                    "passport_dob",
                    moment(val).format("YYYY-MM-DD")
                  );
                }}
                value={values.passport_dob}
                name='passport_dob'
                maxDate={moment().subtract(18, "years")}
                onBlur={handleBlur}
                error={touched.passport_dob && Boolean(errors.passport_dob)}
                helperText={touched.passport_dob && errors.passport_dob}
              /> 
        </InputWrapper>
      </>
    )}              </>
            )}
          
          {/* Non-ICICI block */}

          {(values?.is_ckyc_no === "Y" && product_data?.INSURANCE_SLUG !== "icici_lombard_general") && (
            <>
              <StyledInput
                label='CKYC Number'
                size={"small"}
                onChange={handleChange}
                name='ckyc_no'
                value={values.ckyc_no}
                onBlur={handleBlur}
                error={touched.ckyc_no && Boolean(errors.ckyc_no)}
                helperText={touched.ckyc_no && errors.ckyc_no}
              />
            </>
          )}
          {values?.is_ckyc_no === "N" && product_data?.INSURANCE_SLUG !== "icici_lombard_general" && (
            <>
              <Select
                label='Method of verification'
                size={"small"}
                handleChange={(value) => {
                  setFieldValue("method_of_verification", value);
                }}
                selectedValue={values.method_of_verification}
                options={verification_methods || []}
                name='method_of_verification'
                onBlur={handleBlur}
                error={
                  touched.method_of_verification &&
                  Boolean(errors.method_of_verification)
                }
                helperText={
                  touched.method_of_verification &&
                  errors.method_of_verification
                }
              />
              {values.method_of_verification === "pan" && (
                <StyledInput
                  label='PAN Number'
                  size={"small"}
                  onChange={(e) => {
                    uppercase(e);
                    handleChange(e);
                  }}
                  name='pan_no'
                  value={values.pan_no}
                  onBlur={handleBlur}
                  inputProps={{
                    maxLength: 10,
                  }}
                  error={touched.pan_no && Boolean(errors.pan_no)}
                  helperText={touched.pan_no && errors.pan_no}
                />
              )}


          <Box>
            <Typography variant='subtitle1' component={"p"}>
              Photo
            </Typography>
            <StyledInput
              type='file'
              size={"small"}
              onChange={(e: any) => {
                setFieldValue("photo_doc", e.currentTarget.files[0]);
              }}
              name='photo_doc'
              error={touched.photo_doc && Boolean(errors.photo_doc)}
              helperText={touched.photo_doc && errors.photo_doc}
            />
          </Box>
          </>
                  )}
                            <StyledButton type='submit' variant='contained' size='medium'>
            Proceed to Final Submit
          </StyledButton>
        </Stack>
      </form>
    </>
  );
};

const SummaryComp: React.FC = () => {
  return <></>;
};
const DigitCKYCDetails = () => {
  return (
    <>
      <FormCard
        title='CKYC Details'
        index={4}
        EditComp={EditComp}
        SummaryComp={SummaryComp}
      />
    </>
  );
};

export default DigitCKYCDetails;
const verification_methods = [
  {
    OPTION_KEY: "pan",
    OPTION_VAL: "PAN Number",
  },
  {
    OPTION_KEY: "doc",
    OPTION_VAL: "Physical Documents",
  },
];

const documents = [
  {
    OPTION_KEY: "D20",
    OPTION_VAL: "CGHS ECHS CARD",
  },
  {
    OPTION_KEY: "D21",
    OPTION_VAL: "PHOTO CREDIT CARD",
  },
  {
    OPTION_KEY: "D22",
    OPTION_VAL: "IT PROOF",
  },
  {
    OPTION_KEY: "D23",
    OPTION_VAL: "PHOTO PENSIONER CARD",
  },
  {
    OPTION_KEY: "D24",
    OPTION_VAL: "BANK STATEMENT",
  },
  {
    OPTION_KEY: "D25",
    OPTION_VAL: "AADHAAR IMAGE",
  },
  {
    OPTION_KEY: "D26",
    OPTION_VAL: "DRIVING LICENSE IMAGE",
  },
  {
    OPTION_KEY: "D27",
    OPTION_VAL: "VOTER ID IMAGE",
  },
  {
    OPTION_KEY: "D28",
    OPTION_VAL: "PASSPORT IMAGE",
  },
];
const verification_methods_icici = [
  {
    OPTION_KEY: "pancard",
    OPTION_VAL: "Pancard Details",
  },
  {
    OPTION_KEY: "aadharcard",
    OPTION_VAL: "Aadhar Details",
  },
  {
    OPTION_KEY: "drivingdetails",
    OPTION_VAL: "Driving License",
  },
  {
    OPTION_KEY: "passportdetails",
    OPTION_VAL: "Passport Details",
  },
]

const genderOptions = [
  {
    OPTION_VAL: "Male",
    OPTION_KEY: "M",
  },
  {
    OPTION_VAL: "Female",
    OPTION_KEY: "F",
  },
];