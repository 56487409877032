import styled from "@emotion/styled";
import { OutlinedTextFieldProps, TextField } from "@mui/material";

interface StyledInputProps extends Partial<OutlinedTextFieldProps> {
  colored?: boolean;
  enlarge?: boolean;
  label?: string;
  fixedPart?: string;
  maxLength?: number; // Add maxLength here
}

const CustomInput = styled(TextField)<StyledInputProps>(
  ({ size, colored, enlarge }) => ({
    ...(colored && {
      ".Mui-focused": {
        color: "var(--primary-color)",
      },
    }),
    ...(size === "small" && {
      ".MuiOutlinedInput-root": {
        height: "50px",
      },
      ".MuiInputLabel-root": {
        top: "3px",
      },
      ".MuiInputLabel-shrink": {
        top: "-1px",
      },
    }),
    ...(enlarge && {
      ".MuiOutlinedInput-root": {
        height: "70px",
        fontSize: "22px",
      },
      ".MuiInputLabel-root": {
        fontSize: "22px",
        top: "3px",
      },
      ".MuiInputLabel-shrink": {
        top: "-3px",
      },
    }),
  })
);

const StyledInput = ({ ...props }: StyledInputProps) => {
  const { fixedPart, onChange, onBlur } = props;

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) onBlur(e);
    if (fixedPart && !e.target.value.startsWith(fixedPart)) {
      e.preventDefault();
      e.target.value = fixedPart + e.target.value; // Append fixed part if not present
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e);
    if (fixedPart && !e.target.value.startsWith(fixedPart)) {
      e.preventDefault();
      e.target.value = fixedPart + e.target.value; // Append fixed part if not present
    }
  };

  return (
    <CustomInput
      onBlur={handleBlur}
      onChange={handleChange}
      fullWidth
      {...props}
    />
  );
};

export default StyledInput;