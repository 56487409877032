import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { SelectOptions } from "../Select/Select";
import { boolean } from "yup";
interface StyledRadioGroupProps {
  items: SelectOptions[];
  label: string;
  name: string;
  defaultValue?: string;
  column?: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const StyledRadioGroup: React.FC<StyledRadioGroupProps> = ({
  items,
  label,
  name,
  defaultValue,
  column,
  handleChange,
}) => {
  return (
    <FormControl>
      <FormLabel
        sx={{
          fontSize: "18px",
          color: "var(--tertiary-color)",
          "&.Mui-focused": {
            color: "var(--tertiary-color)",
          },
        }}
      >
        {label}
      </FormLabel>
      <RadioGroup
        defaultValue={defaultValue}
        name={name}
        sx={{ flexDirection: column ? "column" : "row" }}
        onChange={handleChange}
      >
        {items.map((item) => (
          <FormControlLabel
            value={item.OPTION_KEY}
            control={<Radio />}
            label={item.OPTION_VAL}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default StyledRadioGroup;
