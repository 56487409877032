import React from "react";
import { useGetQuotes } from "../../../../services/QuoteServices";
import { QuotesWrapper } from "../../Quotes.style";
import KnowMore from "../KnowMore/KnowMore";
import QuoteCard from "../QuoteCard/QuoteCard";

const Quotes = () => {
  const { fetchedQuotes } = useGetQuotes();
  return (
    <>
      <QuotesWrapper>
        {fetchedQuotes.map((quote) => (
          <>
            <QuoteCard quoteData={quote} />
          </>
        ))}
        <KnowMore />
      </QuotesWrapper>
    </>
  );
};

export default Quotes;
