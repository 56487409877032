import { Box, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import SectionDetail from "../../../components/SectionDetail/SectionDetail";
import TextButton from "../../../components/TextButton/TextButton";
import { useGetProposalDetailsQuery } from "../../../services/ProposalServices";
import { commaSeparatedPrice } from "../../../utils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
const SectionTitle: React.FC = ({ children }) => {
  return (
    <Typography
      mb={1}
      sx={{
        fontWeight: 700,
        color: "var(--dark-grey-1)",
        fontSize: "16px",
      }}
      variant="subtitle1"
      component="p"
    >
      {children}
    </Typography>
  );
};

const PlanCard = () => {
  const { quote_data, product_data, proposal_data } =
    useGetProposalDetailsQuery();
  const navigate = useNavigate();
  const additionalGst =
    proposal_data?.ADDONS.reduce((acc, curr) => {
      return acc + (Number(curr.premium) * 0.18) / 1.18;
    }, 0) || 0;
  if (!proposal_data) return null;
  return (
    <>
      <Paper
        sx={{
          p: 3,
        }}
      >
        <Stack spacing={2} divider={<Divider />}>
          <Box>
            <Box mb={1}>
              <TextButton
                color="primary"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
                onClick={() => {
                  navigate(
                    `/quotes?quote_id=${proposal_data?.QUOTE_ID}&stage=PF`
                  );
                }}
              >
                {" "}
                <ArrowBackIcon
                  sx={{
                    height: "20px",
                    width: "20px",
                    mt: "-2px",
                  }}
                />{" "}
                Change Insurer
              </TextButton>
            </Box>
            <img
              src={product_data?.LOGO_PATH}
              alt=""
              className="plancard-logo"
            />

            <Typography
              variant="h4"
              component="p"
              sx={{
                fontSize: "18px",
                fontWeight: 700,
                mt: 1,
              }}
            >
              {product_data?.INSURANCE_NAME}
            </Typography>
          </Box>

          <Box>
            <SectionTitle>Vehicle Details</SectionTitle>
            <SectionDetail
              title="Manufacturer"
              value={quote_data?.vehicle_make}
            />
            <SectionDetail title="Model" value={quote_data?.vehicle_model} />
            <SectionDetail
              title="Version"
              value={quote_data?.vehicle_variant}
            />
            <SectionDetail
              title="IDV"
              value={commaSeparatedPrice(proposal_data?.IDV)}
            />
            <SectionDetail
              title="Previous Policy Expiry Date"
              value={quote_data?.existing_exp_date}
            />
          </Box>
          <Box>
            <SectionTitle>Premium Breakup</SectionTitle>
            <SectionDetail
              title="Basic OD Premium"
              value={commaSeparatedPrice(proposal_data?.BASIC_OD)}
            />
            <SectionDetail
              title="Basic TP Premium"
              value={commaSeparatedPrice(proposal_data?.BASIC_TP)}
            />
            <SectionDetail
              title="NCB Discount"
              value={commaSeparatedPrice(proposal_data?.NCB_DISCOUNT)}
            />
            {proposal_data?.ADDONS.map((addon) => (
              <SectionDetail
                title={addon.name}
                value={commaSeparatedPrice(Number(addon.premium) / 1.18)}
              />
            ))}
            <SectionDetail
              title="GST"
              value={commaSeparatedPrice(
                Math.round(proposal_data?.GST + additionalGst)
              )}
            />
          </Box>
          <Box>
            <SectionTitle>Policy Details</SectionTitle>
            <SectionDetail
              title="Policy Type"
              value={product_data?.PRODUCT_NAME}
            />
          </Box>
          <Box>
            <SectionDetail
              main
              title="Premium"
              value={commaSeparatedPrice(proposal_data?.TOTAL_PREMIUM)}
            />
          </Box>
        </Stack>
      </Paper>
    </>
  );
};

export default PlanCard;
