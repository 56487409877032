import { Cancel, CheckCircle } from "@mui/icons-material";
import React, { useState } from "react";
import { StyledButton } from "../../../../components/Button/Button";
import { AddonsData, Card, QuoteData } from "../../Quotes.style";
import { Filter, FilterName, FilterValue } from "../Filters/Filters.style";
import {
  PremiumInterface,
  ProductInterface,
} from "./../../../../services/QuoteServices";
import { commaSeparatedPrice } from "./../../../../utils";
import { Skeleton, CircularProgress, Box } from "@mui/material";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useInitiateProposalMutation } from "../../../../services/ProposalServices";
import useUrlQuery from "../../../../hooks/useUrlQuery";
import { useDispatch } from "react-redux";
import {
  editExistingPolicyDetails,
  openSeeDetails,
} from "../../../../modules/quotes.slice";
import TextButton from "../../../../components/TextButton/TextButton";
import useFilters from "../../../../hooks/useFilters";
import PADisclaimer from "../PADisclaimer/PADisclaimer";
interface QuoteCardProps {
  quoteData: {
    isQuoteLoading: boolean;
    premium_data: PremiumInterface | undefined;
    product_data: ProductInterface | undefined;
  };
}
const availableAddons = {
  NILL_DEPT: "Zero Dep",
  PA_COVER: "PA Cover",
};

const AddOn: React.FC<{
  isChecked: boolean | undefined;
  name: string;
}> = ({ name, isChecked }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          "@media (max-width: 768px)": {
            fontSize: "14px",
          },
        }}
      >
        {isChecked ? (
          <>
            <CheckCircle
              color='success'
              fontSize={"small"}
              sx={{
                "@media (max-width: 768px)": {
                  height: "16px",
                  width: "16px",
                },
              }}
            />{" "}
            {name}
          </>
        ) : (
          <>
            <Cancel
              sx={{
                "@media (max-width: 768px)": {
                  height: "16px",
                  width: "16px",
                },
              }}
              color='error'
              fontSize={"small"}
            />{" "}
            {name}
          </>
        )}
      </Box>
    </>
  );
};
const QuoteCard: React.FC<QuoteCardProps> = ({ quoteData }) => {
  const { isQuoteLoading, premium_data, product_data } = quoteData;
  const { getUrlQuery } = useUrlQuery();
  const dispatch = useDispatch();
  const stage = getUrlQuery("stage");
  const { addOns, isPADisclaimerAccepted } = useTypedSelector(
    (state) => state.quote
  );
  const canOpt = premium_data?.PREMIUM_DATA?.ADDONS?.CAN_OPT;
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  const isPAOpted = addOns.includes("PA_COVER");
  const isOD = product_data?.POLICY_TYPE === "OD";
  const additionalPremium = addOns.reduce((acc, curr) => {
    if (canOpt && canOpt[curr] !== "NOT FOUND") {
      return acc + Number(canOpt[curr]);
    } else return acc;
  }, 0);

  const { initiateProposal, isSubmitting } = useInitiateProposalMutation();
  const selected_addons = addOns
    .filter((item) => canOpt && canOpt[item] !== "NOT FOUND")
    .map((item) => {
      return {
        name: availableAddons[item],
        slug: item,
        premium: canOpt && canOpt[item],
      };
    });
  const onBuyNow = () => {
    if (stage !== "PF") {
      dispatch(editExistingPolicyDetails());
      return;
    }

    if (!(isPAOpted || isOD || isPADisclaimerAccepted)) {
      setOpen(true);
      return;
    }
    if (product_data && premium_data)
      initiateProposal({
        PRODUCT_ID: product_data.PRODUCTID,
        COMPANY_SLUG: product_data.INSURANCE_SLUG,
        TOTAL_PREMIUM: (
          +premium_data?.PREMIUM_DATA.TOTAL_PREMIUM + +additionalPremium
        ).toString(),
        GST:
          +premium_data?.PREMIUM_DATA.TOTAL_PREMIUM -
          +premium_data?.PREMIUM_DATA.NET_PREMIUM,
        IDV: premium_data.PREMIUM_DATA.IDV,
        BASIC_OD: premium_data.PREMIUM_DATA.BASIC_OD,
        BASIC_TP: premium_data.PREMIUM_DATA.BASIC_TP,
        NCB_DISCOUNT: premium_data.PREMIUM_DATA.NCB_DISCOUNT,
        ADDONS: selected_addons,
        CORRELATION_ID: premium_data?.PREMIUM_DATA?.CORRELATION_ID,
      });
  };
  return (
    <>
      <Card>
        {!isQuoteLoading && (
          <div className='plan-type'>{product_data?.PRODUCT_NAME}</div>
        )}
        {isQuoteLoading ? (
          <Skeleton variant={"rectangular"} height='60px' />
        ) : (
          <img src={quoteData.product_data?.LOGO_PATH} alt='logo' />
        )}
        <AddonsData>
          {isQuoteLoading ? (
            <Skeleton variant='text' width={"100%"} />
          ) : (
            addOns.map((item) => (
              <>
                <AddOn
                  isChecked={canOpt && canOpt[item] !== "NOT FOUND"}
                  name={availableAddons[item]}
                />
              </>
            ))
          )}
        </AddonsData>
        <QuoteData>
          <FilterName>IDV :</FilterName>
          {isQuoteLoading ? (
            <Skeleton variant='text' width={"100px"} />
          ) : (
            <FilterValue>
              {commaSeparatedPrice(quoteData.premium_data?.PREMIUM_DATA.IDV)}
            </FilterValue>
          )}
        </QuoteData>
        <StyledButton
          isBuyNow
          variant='outlined'
          enlarged
          onClick={onBuyNow}
          sx={{
            "@media (max-width: 768px)": {
              width: "100%",
              padding: "6px 6px !important",
              "& > .btn-span": {
                fontSize: "12px !important",
                marginRight: "8px !important",
              },
              fontSize: "14px !important",
            },
          }}
        >
          {isQuoteLoading ? (
            <CircularProgress
              sx={{
                color: "var(--secondary-color)",
              }}
            />
          ) : (
            <>
              <span className='btn-span'>Buy Now</span>
              {commaSeparatedPrice(
                quoteData.premium_data?.PREMIUM_DATA.TOTAL_PREMIUM,
                additionalPremium
              )}
            </>
          )}
        </StyledButton>
        <TextButton
          onClick={() =>
            dispatch(
              openSeeDetails({
                premium_data,
                product_data,
                selected_addons,
              })
            )
          }
          color='primary'
          style={{
            marginTop: "0",
          }}
        >
          View Plan Details
        </TextButton>
      </Card>
      <PADisclaimer onBuyNow={onBuyNow} open={open} onClose={onClose} />
    </>
  );
};

export default QuoteCard;
