import { Step, StepLabel, Stepper, useMediaQuery } from "@mui/material";
import { useDispatch } from "react-redux";
import Modal from "../../../../components/Modal/Modal";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import useUrlQuery from "../../../../hooks/useUrlQuery";
import { closePopups, setActiveVehicleDetails } from "../../../../modules";
import Brand from "./components/Brand";
import Model from "./components/Model";
import Registration from "./components/Registration";
import Variant from "./components/Variant";
import { Header, Wrapper } from "./VehicleDetails.style";
import Nav from "../../../../components/Header/Header";
const VehicleDetails = () => {
  const dispatch = useDispatch();
  const { removeUrlQuery } = useUrlQuery();
  const { showVehicleDetailsPopup, activeVehicleDetails } = useTypedSelector(
    (state) => state.landing
  );
  const onClose = () => {
    dispatch(closePopups());
    removeUrlQuery("flow");
    removeUrlQuery("stage");
  };
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <>
      <Modal
        open={showVehicleDetailsPopup}
        onClose={onClose}
        maxWidth='md'
        fullScreen={isMobile}
      >
        {isMobile && <Nav />}
        <Wrapper>
          <Header>
            <Stepper
              activeStep={activeVehicleDetails}
              alternativeLabel
              sx={{
                padding: "0px",
                width: "100%",
                "&  .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
                  marginTop: "8px",
                  fontWeight: "600",
                  "&.Mui-active": {
                    color: "var(--primary-color)",
                    fontWeight: "700",
                  },
                },
              }}
            >
              <Step>
                <StepLabel
                  onClick={() => {
                    if (activeVehicleDetails > 0)
                      dispatch(setActiveVehicleDetails(0));
                  }}
                >
                  Brand
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  onClick={() => {
                    if (activeVehicleDetails > 1)
                      dispatch(setActiveVehicleDetails(1));
                  }}
                >
                  Model
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  onClick={() => {
                    if (activeVehicleDetails > 2) {
                      dispatch(setActiveVehicleDetails(2));
                    }
                  }}
                >
                  Variant
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  onChange={() => {
                    if (activeVehicleDetails > 3) {
                      dispatch(setActiveVehicleDetails(3));
                    }
                  }}
                >
                  Registration
                </StepLabel>
              </Step>
            </Stepper>
          </Header>
          {activeVehicleDetails === 0 && <Brand />}
          {activeVehicleDetails === 1 && <Model />}
          {activeVehicleDetails === 2 && <Variant />}
          {activeVehicleDetails === 3 && <Registration />}
        </Wrapper>
      </Modal>
    </>
  );
};

export default VehicleDetails;
