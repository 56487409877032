import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Checkbox from "../../../../components/Checkbox/Checkbox";
import useFilters from "../../../../hooks/useFilters";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import useUrlQuery from "../../../../hooks/useUrlQuery";
import { setSelectedPlanType } from "../../../../modules/quotes.slice";
import { useGetEligibleProductsQuery } from "../../../../services/CarServices";
import { commaSeparatedPrice } from "../../../../utils";
import IDVModal from "./components/IDV";
import PlanTypeModal from "./components/PlanType";
import {
  FilterName,
  FiltersWrapper,
  FilterValue,
  Filter,
} from "./Filters.style";
const idvName = {
  LIDV: "Lowest",
  RIDV: "Reco.",
  HIDV: "Highest",
  Custom: "",
};
const Filters = () => {
  const { addOns, selected_idv, selected_plantype } = useTypedSelector(
    (state) => state.quote
  );
  const { setUrlQuery } = useUrlQuery();
  const { requestData, isEligibleProductsLoading } =
    useGetEligibleProductsQuery();
  const planTypes = requestData?.policy_type;
  const dispatch = useDispatch();

  useEffect(() => {
    if (selected_plantype === "ALL" && planTypes && planTypes.length === 1) {
      dispatch(setSelectedPlanType(planTypes[0].code));
    }
  }, [planTypes]);
  const { selectedAddonsParam } = useFilters();
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <>
      <FiltersWrapper>
        <Filter onClick={() => setUrlQuery("filter", "planType")}>
          <FilterName>Plan Type:</FilterName>
          <FilterValue>
            {planTypes?.find((planType) => planType.code === selected_plantype)
              ?.display_name || "All Plans"}
          </FilterValue>
        </Filter>
        <Filter idv onClick={() => setUrlQuery("filter", "idv")}>
          <FilterName>Insured Value (IDV):</FilterName>
          <FilterValue>
            {!selected_idv.type
              ? "Select IDV"
              : `${commaSeparatedPrice(selected_idv.value)} (${
                  idvName[selected_idv.type]
                })`}
          </FilterValue>
        </Filter>
        {!isMobile && (
          <>
            <Filter>
              <Checkbox
                label='Zero Depreciation'
                checked={addOns.includes("NILL_DEPT")}
                onChange={(value) =>
                  setUrlQuery(
                    "addOns",
                    value
                      ? String(+selectedAddonsParam + 2)
                      : String(+selectedAddonsParam - 2)
                  )
                }
              />
            </Filter>
            <Filter>
              <Checkbox
                label='Personal Accident '
                checked={addOns.includes("PA_COVER")}
                onChange={(value) =>
                  setUrlQuery(
                    "addOns",
                    value
                      ? String(+selectedAddonsParam + 4)
                      : String(+selectedAddonsParam - 4)
                  )
                }
              />
            </Filter>
          </>
        )}
        {isMobile && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              "& > div": {
                padding: "6px",
              },
            }}
          >
            <Filter>
              <Checkbox
                label='Zero Depreciation'
                checked={addOns.includes("NILL_DEPT")}
                onChange={(value) =>
                  setUrlQuery(
                    "addOns",
                    value
                      ? String(+selectedAddonsParam + 2)
                      : String(+selectedAddonsParam - 2)
                  )
                }
              />
            </Filter>
            <Filter>
              <Checkbox
                label='Personal Accident '
                checked={addOns.includes("PA_COVER")}
                onChange={(value) =>
                  setUrlQuery(
                    "addOns",
                    value
                      ? String(+selectedAddonsParam + 4)
                      : String(+selectedAddonsParam - 4)
                  )
                }
              />
            </Filter>
          </Box>
        )}
      </FiltersWrapper>
      <PlanTypeModal />
      <IDVModal />
    </>
  );
};

export default Filters;
